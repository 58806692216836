.camp-List{
    width: 1580px;
    height: 50px;
    background-color: #2222221A;
margin-top: 10px;
}
.email-popup{
    width: 1240px;
    height: 610px;
    border-radius: 10px;
    background-color: white; 
}
.popup-overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000CC;
    display: flex;
    align-items: center;
    justify-content: center;
}
.email-clear-popup{
    background: none;
    border: none;
    width: 34px;
    height: 34px;
    margin-left: 1200px;
}
.email-title{
    font-size: 23px;
    font-weight: 500;
    font-family: Roboto, Medium;
    color: #1F074F;
    padding-left: 500px; 
}
.search{
    margin-left: 1100px;
}
.email-second-popup{
    width: 1200px;
    height: 800px;
    border-radius: 10px;
    background-color: white;
}
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .email-title2{
    font-size: 23px;
    font-weight: 500;
    font-family: Roboto, Medium;
    color: #1F074F;
    padding-left: 400px;
  }
  .email-preview-popup{
    width: 1200px;
    height: 800px;
    border-radius: 10px;
    background-color: white;
  }
  .email-preview-clear{
    background: none;
    border: none;
    width: 34px;
    height: 34px;
    margin-left: 1160px;
  }
  .email-content{
    width: 1174px;
height: 487px;
overflow-y: auto;
margin-left: 50px;
margin-right: 50px;
  }
  .time-popup{
    width: 1200px;
    height: 400px;
    border-radius: 10px;
    background-color: white;
  }
  .time-clear-popup{
    background: none;
    border: none;
    width: 34px;
    height: 34px;
    margin-left: 1160px;
  }
  .time-title{
    font-size: 23px;
    font-weight: 500;
    font-family: Roboto, Medium;
    color: #1F074F;
    padding-left: 370px;
  }
  .code-popup{
    width: 1200px;
    height: 450px;
    border-radius: 10px;
    background-color: white;
  }
  .site-btn{
    background-color: none;
    background: white;
    border: none;
   margin-top: 100px;
    margin-left: 200px;
    cursor: pointer;
  }
  .copy-btn{
    background-color: none;
    background: white;
    border: none;
   margin-top: 100px;
   margin-right: 200px;
   cursor: pointer;
  }
  .code-card{
    width: 678px;
    height: 262px;
    overflow-x: auto;
    padding-left: 150px;
    padding-right: 150px;
    background-color:beige;
   margin-left: 100px;
   font-size: 16px;
   color: #E3E3E3;
  }
  .copy-code-btn{
    width: 186px;
    height: 42px;
    border-radius: 5px;
    font-size: 18px;
    background: #1F074F;
    color: #09D8C4;
    cursor: pointer;
    margin-left: 900px;
    margin-top: 20px;
  }
  .p-main-div {
    width: auto;
    background: white;
    margin-top: 40px;
    margin-left: 50px;
    margin-right: 50px;
    border-radius: 10px;
    padding: 0px 10px 0px 20px;
  }
  
  .company-main-height {
    padding: 0px;
    width: calc(100% - 260px) !important;
    background: #fafafa;
  }
  
  .company-dashboard-main-div {
    /* width: 100%; */
    background: white;
    border-radius: 10px;
    margin: 20px 20px 0px 20px;
    padding: 20px;
  }