.reset-password-card{
    width: 608px;
    background-color: #FFFFFF;
    border-radius: 20px;
    margin-top: 50px !important;
    margin:auto;
    padding: 40px 0px;
}

.reset-password-inner-box{
    width: 480px !important;
    margin: auto;
}

.rest-password-text{
    font-weight:700;
    font-size:32px;
    color: #323B4B;
    margin-top: 40px;
    margin-bottom: 0px;
}

@media (max-width: 425px) {
    .reset-password-card{
        width: 310px !important;
        background-color: rgb(247, 249, 252);
    }

    .reset-password-inner-box{
        width: 310px !important;
    }

    .rest-password-text{
        font-size:22px;
        margin-top: 80px;
        margin-bottom: 0px;
    }
  }