.company-earningrules-addform-cancel-btn{
    width: 240px;
    height: 52px;
    border: 1px solid #0B7974;
    color: #0B7974;
    background-color: white;
    border-radius: 10px;
    margin-top: 30px;
    /* margin-left: 1100px; */
}

.company-earningrules-addform-submit-btn {
    width: 240px;
    height: 52px;
    border: none;
    color: white;
    background-color: #0B7974;
    border-radius: 10px;
    margin-top: 30px;
    margin-left: 20px;}
@media (max-width: 1024px) {
    .company-earningrules-addform-cancel-btn,.company-earningrules-addform-submit-btn {
      width: 180px;
    }   
  }