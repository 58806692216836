/*-----------------------------------------------------------------------------------

    Theme Name: Starter - Starter HTML5 Template
    Author: STARTER
    Support: support@starter.com
    Description: Starter - Starter HTML5 Template
    Version: 1.0

-----------------------------------------------------------------------------------


/************ TABLE OF CONTENTS ***************

  01. Theme Default CSS
  02. background CSS
  03. buttons css

**********************************************/
/**----------------------------------------
START: Theme Default CSS
----------------------------------------*/
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,300;6..12,400;6..12,500;6..12,600;6..12,700;6..12,800;6..12,900&family=Readex+Pro:wght@200;300;400;500;600;700&display=swap");
:root {
  /**
     @Font-Family Declaration
   */
  --tj-ff-body: 'Nunito Sans', sans-serif;
  --tj-ff-heading: 'Readex Pro', sans-serif;
  --tj-ff-fontawesome: "Font Awesome 6 Pro";
  /**
     @Font-weight Declaration
   */
  --tj-fw-normal: normal;
  --tj-fw-thin: 100;
  --tj-fw-elight: 200;
  --tj-fw-light: 300;
  --tj-fw-regular: 400;
  --tj-fw-medium: 500;
  --tj-fw-sbold: 600;
  --tj-fw-bold: 700;
  --tj-fw-ebold: 800;
  --tj-fw-black: 900;
  /**
     @Font-Size Declaration
   */
  --tj-fs-body: 16px;
  --tj-fs-h1: 70px;
  --tj-fs-h2: 50px;
  --tj-fs-h3: 40px;
  --tj-fs-h4: 30px;
  --tj-fs-h5: 22px;
  --tj-fs-h6: 18px;
  /**
     @Color Declaration
   */
  --tj-color-common-white: #ffffff;
  --tj-color-common-black: #000000;
  --tj-color-common-black-2: #030303;
  --tj-color-common-black-3: #333333;
  --tj-color-heading-primary: #000000;
  --tj-color-gradient-1: #0b7974;
  --tj-color-gradient-2: #4a56ab;
  --tj-color-theme-secondary: #ff5833;
  --tj-color-gradient-3: #ff5833;
  --tj-color-text-body: #6f7782;
  --tj-color-theme-primary: #0b7974;
  --tj-color-theme-secondary: #ff5833;
  --tj-color-light-1: #ff5833;
  --tj-color-light-2: #bdaaf5;
  --tj-color-light-3: #c8b8f7;
  --tj-color-light-4: #d3c6f8;
  --tj-color-light-5: #ded4fa;
  --tj-color-light-6: #e9e3fc;
  --tj-color-light-7: #f4f1fd;
  --tj-color-grey-1: #6f7782;
  --tj-color-grey-2: #a1acb3;
  --tj-color-grey-3: #bdc5ca;
  --tj-color-grey-4: #dcdee7;
  --tj-color-grey-5: #f2f5f9;
  --tj-color-grey-6: #fafafc;
  --tj-color-extra-1: #ff647c;
  --tj-color-extra-2: #ffb800;
  --tj-color-extra-3: #5fcc8b;
  --tj-color-extra-4: #7b61ff;
  --tj-color-extra-5: #f1eeff;
  --tj-color-extra-6: #fff2f4;
  --tj-color-extra-7: #fffcf4;
  --tj-color-border-1: #ededed;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/**
  Typography CSS
*/
body {
  font-family: var(--tj-ff-body);
  font-size: var(--tj-fs-body);
  font-weight: var(--tj-fw-medium);
  color: var(--tj-color-text-body);
  line-height: 1.5;
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--tj-ff-heading);
  color: var(--tj-color-heading-primary);
  margin-top: 0px;
  font-weight: var(--tj-fw-sbold);
  line-height: 1.2;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

h1 {
  font-size: var(--tj-fs-h1);
  font-weight: var(--tj-fw-bold);
  line-height: 90px;
}

h2 {
  font-size: var(--tj-fs-h2);
}

h3 {
  font-size: var(--tj-fs-h3);
}

h4 {
  font-size: var(--tj-fs-h4);
}

h5 {
  font-size: var(--tj-fs-h5);
}

h6 {
  font-size: var(--tj-fs-h6);
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  font-size: inherit;
  color: inherit;
  font-weight: inherit;
  line-height: inherit;
  display: block;
}

h1:hover a,
h2:hover a,
h3:hover a,
h4:hover a,
h5:hover a,
h6:hover a {
  font-size: inherit;
  color: inherit;
  font-weight: inherit;
}

ul {
  margin: 0px;
  padding: 0px;
}

a,
.btn,
button,
span,
p,
input,
select,
textarea,
li,
img,
svg path,
*::after,
*::before,
h1,
h2,
h3,
h4,
h5,
h6 {
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

a:focus,
.button:focus {
  text-decoration: none;
  outline: none;
}

a:focus,
a:hover {
  color: inherit;
  text-decoration: none;
}

a,
button {
  color: inherit;
  outline: none;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

button:focus,
button:focus:not(:focus-visible) {
  outline: 0;
  box-shadow: 0 0 0;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

select,
.nice-select,
input:not([type=submit]):not([type=radio]):not([type=checkbox]),
textarea {
  outline: none;
  background-color: --tj-color-common-white;
  height: auto;
  width: 100%;
  font-size: 16px;
  border: 1px solid var(--tj-color-common-white);
  color: var(--tj-color-common-black);
  padding-left: 29px;
  padding-right: 29px;
}

input[type=color] {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: none;
  border: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
  padding: 0;
  border-radius: 50%;
}

::-webkit-scrollbar {
  height: 4px;
  width: 4px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: var(--tj-color-theme-primary);
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.75);
}

::-webkit-scrollbar-corner {
  background: transparent;
}

*::-moz-selection {
  background: var(--tj-color-common-black);
  color: var(--tj-color-common-white);
  text-shadow: none;
}

::-moz-selection {
  background: var(--tj-color-common-black);
  color: var(--tj-color-common-white);
  text-shadow: none;
}

::selection {
  background: var(--tj-color-common-black);
  color: var(--tj-color-common-white);
  text-shadow: none;
}

*::-webkit-input-placeholder {
  color: var(--tj-color-common-black);
  font-size: var(--tj-fs-body);
  opacity: 1;
}
*:-moz-placeholder {
  color: var(--tj-color-common-black);
  font-size: var(--tj-fs-body);
  opacity: 1;
}
*::-moz-placeholder {
  color: var(--tj-color-common-black);
  font-size: var(--tj-fs-body);
  opacity: 1;
}
*:-ms-input-placeholder {
  color: var(--tj-color-common-black);
  font-size: var(--tj-fs-body);
  opacity: 1;
}
*::placeholder {
  color: var(--tj-color-common-black);
  font-size: var(--tj-fs-body);
  opacity: 1;
}

/**
 	Common Classes CSS
*/
.w-img img {
  width: 100%;
}

img {
  max-width: 100%;
}

.m-img img {
  max-width: 100%;
}

.fix {
  overflow: hidden;
}

.clear {
  clear: both;
}

.f-left {
  float: left;
}

.f-right {
  float: right;
}

.z-index-1 {
  z-index: 1;
}

.z-index-11 {
  z-index: 11;
}

.overflow-y-visible {
  overflow-x: hidden;
  overflow-y: visible;
}

.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute;
}

.include-bg {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.b-radius {
  border-radius: 6px;
}

.list-gap {
  margin: 0;
  padding: 0;
  list-style: none;
}

/**----------------------------------------
START: Button CSS
----------------------------------------*/
.tj-primary-btn {
  color: var(--tj-color-common-white);
  font-weight: var(--tj-fw-sbold);
  font-size: 15px;
  padding: 15px 30px;
  border-radius: 58px;
  overflow: hidden;
  font-family: var(--tj-ff-body);
  background: transparent;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
  position: relative;
  z-index: 9;
  display: inline-block;
  outline: none;
  box-shadow: 15px 9px 30px 0px rgba(117, 77, 233, 0.2);
}
.tj-primary-btn::after {
  position: absolute;
  content: " ";
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, var(--tj-color-gradient-1) 0%, var(--tj-color-gradient-3) 100%, var(--tj-color-gradient-2) 100%);
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}
.tj-primary-btn:hover {
  background: transparent;
  color: var(--tj-color-common-white);
}
.tj-primary-btn:hover::after {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  color: var(--tj-color-common-white);
}

.tj-secondary-btn {
  font-weight: var(--tj-fw-sbold);
  font-size: 15px;
  padding: 14px 30px;
  color: var(--tj-color-gradient-1);
  border-radius: 58px;
  border: 1.5px solid var(--tj-color-gradient-1);
  overflow: hidden;
  font-family: var(--tj-ff-body);
  background: transparent;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
  position: relative;
  display: inline-block;
  outline: none;
}
.tj-secondary-btn::after {
  position: absolute;
  content: " ";
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}
.tj-secondary-btn:hover {
  border-color: transparent;
  color: var(--tj-color-common-white);
}
.tj-secondary-btn:hover::after {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  color: var(--tj-color-common-white);
  background: linear-gradient(90deg, var(--tj-color-gradient-1) 0%, var(--tj-color-gradient-3) 100%, var(--tj-color-gradient-2) 100%);
}

.tj-transparent-btn {
  font-weight: var(--tj-fw-sbold);
  font-size: 15px;
  padding: 17px 30px;
  color: var(--tj-color-common-black);
  border-radius: 58px;
  border: 2px solid var(--tj-color-light-6);
  box-shadow: 15px 9px 30px 0px rgba(117, 77, 233, 0.15);
  overflow: hidden;
  font-family: var(--tj-ff-body);
  background: transparent;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
  position: relative;
  display: inline-block;
  outline: none;
}
.tj-transparent-btn::after {
  position: absolute;
  content: " ";
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}
.tj-transparent-btn:hover {
  border-color: transparent;
  color: var(--tj-color-common-white);
}
.tj-transparent-btn:hover::after {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  color: var(--tj-color-common-white);
  background: linear-gradient(90deg, var(--tj-color-gradient-1) 0%, var(--tj-color-gradient-3) 100%, var(--tj-color-gradient-2) 100%);
}

.tj-white-btn {
  color: var(--gradients-color-gd-1);
  font-weight: var(--tj-fw-sbold);
  font-size: 15px;
  padding: 17px 30px;
  border-radius: 58px;
  overflow: hidden;
  font-family: var(--tj-ff-body);
  background: transparent;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
  position: relative;
  display: inline-block;
  outline: none;
}
.tj-white-btn::after {
  position: absolute;
  content: " ";
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--tj-color-common-white);
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}
.tj-white-btn:hover {
  background: transparent;
  color: var(--tj-color-common-white);
  background: var(--tj-color-gradient-3);
}
.tj-white-btn:hover::after {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  color: var(--tj-color-common-white);
}

.tj-black-btn {
  font-weight: var(--tj-fw-sbold);
  font-size: 15px;
  padding: 15px 30px;
  color: var(--tj-color-gradient-1);
  border-radius: 5px;
  border: 1.5px solid var(--tj-color-gradient-1);
  overflow: hidden;
  font-family: var(--tj-ff-body);
  background: transparent;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
  position: relative;
  display: inline-block;
  outline: none;
  z-index: 3;
}
.tj-black-btn::after {
  position: absolute;
  content: " ";
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}
.tj-black-btn:hover {
  border-color: transparent;
  color: var(--tj-color-common-white);
}
.tj-black-btn:hover::after {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  color: var(--tj-color-common-white);
  background: linear-gradient(90deg, var(--tj-color-gradient-1) 0%, var(--tj-color-gradient-3) 100%, var(--tj-color-gradient-2) 100%);
}

.tj-primary-black-btn {
  color: var(--tj-color-common-white);
  font-weight: var(--tj-fw-sbold);
  font-size: 15px;
  padding: 15px 30px;
  border-radius: 5px;
  overflow: hidden;
  font-family: var(--tj-ff-body);
  background: transparent;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
  position: relative;
  display: inline-block;
  outline: none;
}
.tj-primary-black-btn::after {
  position: absolute;
  content: " ";
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--tj-color-common-black);
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}
.tj-primary-black-btn:hover {
  background: transparent;
  color: var(--tj-color-common-white);
  background: var(--tj-color-gradient-3);
}
.tj-primary-black-btn:hover::after {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  color: var(--tj-color-common-white);
}

.tj-primary-white-btn {
  color: var(--tj-color-common-white);
  font-weight: var(--tj-fw-sbold);
  font-size: 15px;
  padding: 17px 30px;
  border-radius: 5px;
  overflow: hidden;
  font-family: var(--tj-ff-body);
  background: transparent;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
  position: relative;
  display: inline-block;
  outline: none;
}
.tj-primary-white-btn::after {
  position: absolute;
  content: " ";
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--tj-color-common-black);
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}
.tj-primary-white-btn:hover {
  background: transparent;
  color: var(--tj-color-common-black);
  background: var(--tj-color-common-white);
}
.tj-primary-white-btn:hover::after {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  color: var(--tj-color-common-white);
}

.contact-btn {
  width: 100%;
}

/* !END: Button CSS */
/**----------------------------------------
START: Theme Header CSS
----------------------------------------*/
.tj-header-area {
  transition: 0.4s;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
  padding: 23px 0;
}
.tj-header-area.bg-background-color {
  background-color: var(--tj-color-common-white);
}
.tj-header-area.tj-header-sticky {
  padding: 15px 0;
  box-shadow: 15px 9px 30px 0px rgba(117, 77, 233, 0.15);
  background: var(--tj-color-common-white);
  animation: sticky 0.9s;
}

.header-content-area {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.header-content-area .logo-area {
  max-width: 185px;
  width: 100%;
}
.header-content-area .logo-area a {
  display: inline-block;
}

.header-button-box {
  display: flex;
  align-items: center;
  justify-content: end;
}
.header-button-box .header-button {
  display: inline-flex;
}
.header-button-box .tj-singup-button {
  margin-left: 25px;
}

.header-content-two {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@-webkit-keyframes sticky {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}
@keyframes sticky {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}
/* !END: Theme Header CSS */
/**----------------------------------------
START: Animate CSS
----------------------------------------*/
.shake-y {
  -webkit-animation: shakeY 5s ease-in-out infinite;
  animation: shakeY 5s ease-in-out infinite;
}

.pulse {
  -webkit-animation: pulse 3s ease-in-out infinite;
  animation: pulse 3s ease-in-out infinite;
}

.skew-spin {
  -webkit-animation: skewSpin 5s linear infinite;
  animation: skewSpin 5s linear infinite;
}

.left-right-rotate {
  animation: rotated-style3 2s infinite alternate;
}

@-webkit-keyframes spinner {
  to {
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg);
  }
}
@-webkit-keyframes shakeY {
  0% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
  50% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
}
@keyframes shakeY {
  0% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
  50% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
}
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@-webkit-keyframes skewSpin {
  0% {
    -webkit-transform: skewX(-10deg) skewY(-15deg) rotateY(-40deg) rotate(0);
    transform: skewX(-10deg) skewY(-15deg) rotateY(-40deg) rotate(0);
  }
  100% {
    -webkit-transform: skewX(-10deg) skewY(-15deg) rotateY(-40deg) rotate(360deg);
    transform: skewX(-10deg) skewY(-15deg) rotateY(-40deg) rotate(360deg);
  }
}
@keyframes skewSpin {
  0% {
    -webkit-transform: skewX(-10deg) skewY(-15deg) rotateY(-40deg) rotate(0);
    transform: skewX(-10deg) skewY(-15deg) rotateY(-40deg) rotate(0);
  }
  100% {
    -webkit-transform: skewX(-10deg) skewY(-15deg) rotateY(-40deg) rotate(360deg);
    transform: skewX(-10deg) skewY(-15deg) rotateY(-40deg) rotate(360deg);
  }
}
@keyframes rotated-style3 {
  0% {
    transform: rotate(-10deg);
    -webkit-transform: rotate(-10deg);
  }
  100% {
    transform: rotate(10deg);
    -webkit-transform: rotate(10deg);
  }
}
@keyframes hero-thumb-animation {
  0% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes hero-thumb-sm-animation {
  0% {
    -webkit-transform: translateY(-20px) translateX(50px);
    -moz-transform: translateY(-20px) translateX(50px);
    -ms-transform: translateY(-20px) translateX(50px);
    transform: translateY(-20px) translateX(50px);
  }
  100% {
    -webkit-transform: translateY(-20px) translateX(0px);
    -moz-transform: translateY(-20px) translateX(0px);
    -ms-transform: translateY(-20px) translateX(0px);
    transform: translateY(-20px) translateX(0px);
  }
}
@keyframes hero-thumb-sm-2-animation {
  0% {
    -webkit-transform: translateY(-50px);
    -moz-transform: translateY(-50px);
    -ms-transform: translateY(-50px);
    transform: translateY(-50px);
  }
  100% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@keyframes services-triangle {
  0% {
    -webkit-transform: rotate(0deg) translateX(-50px);
    -moz-transform: rotate(0deg) translateX(-50px);
    -ms-transform: rotate(0deg) translateX(-50px);
    transform: rotate(0deg) translateX(-50px);
  }
  100% {
    -webkit-transform: rotate(360deg) translateY(100px);
    -moz-transform: rotate(360deg) translateY(100px);
    -ms-transform: rotate(360deg) translateY(100px);
    transform: rotate(360deg) translateY(100px);
  }
}
/* !END: Animate CSS */
/**----------------------------------------
START: Theme Heading CSS
----------------------------------------*/
.tj-sec-heading {
  position: relative;
  z-index: 3;
}
.tj-sec-heading .title {
  margin-bottom: 25px;
}
.tj-sec-heading .sub-title {
  font-size: 20px;
  font-weight: var(--tj-fw-bold);
  font-family: var(--tj-ff-heading);
  color: var(--tj-color-gradient-1);
  letter-spacing: 4px;
  display: block;
  margin-bottom: 25px;
}

.tj-sec-heading-two {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 3;
}
.tj-sec-heading-two .title {
  margin-bottom: 25px;
}
.tj-sec-heading-two .sub-title {
  font-size: 20px;
  font-weight: var(--tj-fw-bold);
  font-family: var(--tj-ff-heading);
  background: linear-gradient(90deg, var(--tj-color-gradient-1) 0%, var(--tj-color-gradient-3) 100%, var(--tj-color-gradient-2) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 4px;
  display: block;
  margin-bottom: 25px;
}

.active-color {
  color: var(--tj-color-gradient-1);
}

/* !END: Theme Heading CSS */
/**----------------------------------------
START: Hero CSS
----------------------------------------*/
.tj-hero-section {
  padding-top: 185px;
  padding-bottom: 80px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.tj-hero-section .tj-sec-heading .title {
  max-width: 900px;
  margin: auto;
  position: relative;
  z-index: 3;
  margin-bottom: 45px;
}

.hero-lg-image {
  padding: 30px;
  border-radius: 20px;
  border: 2px solid var(--tj-color-common-white);
  background: rgba(117, 77, 233, 0.12);
  box-shadow: 15px 9px 100px 0px rgba(117, 77, 233, 0.15);
}
.hero-lg-image img {
  width: 100%;
}

.hero-content-area {
  margin-bottom: 60px;
}
.hero-content-area .tj-sec-heading {
  position: relative;
  margin-bottom: 25px;
}
.hero-content-area .tj-sec-heading .shape {
  position: relative;
}
.hero-content-area .tj-sec-heading .shape::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 29px;
  bottom: 0;
  left: 0;
  background-image: url(../images/icon/shape.png);
  background-repeat: no-repeat;
  bottom: -5%;
  -webkit-animation: shakeY 5s ease-in-out infinite;
  animation: shakeY 9s ease-in-out infinite;
  background-position: center bottom;
  background-size: contain;
}
.hero-content-area .desc {
  font-size: 18px;
  font-weight: var(--tj-fw-medium);
  display: block;
  margin-bottom: 0;
  max-width: 1050px;
  margin: 0 auto;
}

.tj-hero-section-two {
  padding-top: 235px;
  padding-bottom: 80px;
  position: relative;
}
.tj-hero-section-two .tj-sec-heading-two .title {
  color: var(--tj-color-common-black-3);
  max-width: 840px;
  margin: auto;
  margin-bottom: 25px;
}
.tj-hero-section-two .hero-overly-image {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.tj-hero-section-two .hero-overly-image1 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}
.tj-hero-section-two .hero-image {
  position: relative;
  z-index: 3;
}

.hero-content-two {
  margin-bottom: 45px;
}
.hero-content-two .tj-sec-heading {
  position: relative;
}
.hero-content-two .tj-sec-heading .hero-shape {
  position: absolute;
  top: 50%;
  right: 33%;
}
.hero-content-two .desc {
  font-size: 18px;
  font-weight: var(--tj-fw-medium);
  display: block;
  margin-bottom: 0;
  max-width: 1050px;
  margin: 0 auto;
}

.header-list {
  margin-bottom: 25px;
  position: relative;
  z-index: 3;
}
.header-list ul li {
  display: inline-block;
  padding-left: 25px;
  margin-right: 15px;
  position: relative;
}
.header-list ul li:last-child {
  margin-right: 0;
}
.header-list ul li i {
  margin-right: 5px;
  position: absolute;
  top: 60%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
}

.hero-middle-area {
  position: relative;
  z-index: 3;
}

.hero-input-form {
  margin-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 50px;
}
.hero-input-form .hero-input {
  width: 485px;
}
.hero-input-form .hero-input input[type=email] {
  border-radius: 5px;
  padding: 19px 30px;
  border: 1px solid var(--tj-color-common-black-2);
  background: transparent;
  box-shadow: 15px 9px 30px 0px rgba(117, 77, 233, 0.15);
}

.tj-hero-section-three {
  padding-top: 165px;
  padding-bottom: 60px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.hero-bg-image {
  margin-right: -120px;
}

.hero-left-content {
  margin-left: 31.5%;
}
.hero-left-content .hero-content-three {
  margin-bottom: 45px;
  position: relative;
  z-index: 9;
}
.hero-left-content .hero-content-three .tj-sec-heading-two {
  margin-bottom: 25px;
}
.hero-left-content .hero-content-three .tj-sec-heading-two .title {
  max-width: 550px;
}
.hero-left-content .hero-content-three .tj-sec-heading-two .hero-shape {
  position: absolute;
  top: 50%;
  right: 33%;
}
.hero-left-content .hero-content-three .desc {
  font-size: 18px;
  font-weight: var(--tj-fw-medium);
  display: block;
  margin-bottom: 0;
  max-width: 720px;
}
.hero-left-content .hero-input-form-two {
  margin-bottom: 45px;
  display: flex;
  align-items: center;
  gap: 50px;
}
.hero-left-content .hero-input-form-two .hero-input {
  width: 485px;
}
.hero-left-content .hero-input-form-two .hero-input input[type=email] {
  border-radius: 5px;
  padding: 19px 30px;
  background: var(--tj-color-common-white);
  box-shadow: 15px 9px 30px 0px rgba(117, 77, 233, 0.15);
}
.hero-left-content .hero-input-form-two .tj-hero-button .tj-primary-black-btn {
  width: 150px;
}

.hero-input-form-two .hero-input input::placeholder {
  color: var(--tj-color-grey-1);
  opacity: 1;
}

.hero-input-form-two .hero-input input:-ms-input-placeholder {
  color: var(--tj-color-grey-1);
}

.hero-input-form-two .hero-input input::-ms-input-placeholder {
  color: var(--tj-color-grey-1);
}

.hero-input-form input::placeholder {
  color: var(--tj-color-grey-1);
  opacity: 1;
}

.hero-input-form input:-ms-input-placeholder {
  color: var(--tj-color-grey-1);
}

.hero-input-form input::-ms-input-placeholder {
  color: var(--tj-color-grey-1);
}

/* !END: Hero CSS */
/**----------------------------------------
START: Mainmenu CSS
----------------------------------------*/
/* ----------------------------------
    03. Canvas CSS
 ------------------------------------ */
.tj-overlay-canvas {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(117, 77, 233, 0.7);
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.tj-offcanvas-area {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 485px;
  z-index: 9999;
  background: linear-gradient(90deg, var(--tj-color-gradient-1) 0%, var(--tj-color-gradient-3) 100%, var(--tj-color-gradient-2) 100%);
  padding: 30px 30px 30px;
  right: -450px;
  opacity: 0;
  visibility: visible;
  overflow-y: scroll;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}

.tj-offcanvas-header {
  margin-bottom: 30px;
}

.canvas_expanded .tj-offcanvas-area {
  right: 0;
}

.canvas_expanded .tj-offcanvas-area,
.canvas_expanded .tj-overlay-canvas {
  opacity: 1;
  visibility: visible;
}

.logo-area img {
  max-width: 150px;
  width: 100%;
}

.mobile-navbar-menu {
  overflow: hidden;
}

.mean-container a.meanmenu-reveal {
  display: none !important;
}

.mobile-navbar-menu .mean-bar {
  background: transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.mean-nav ul li a.mean-expand i.fa-plus {
  display: block;
}

.mean-nav ul li a.mean-expand i.fa-minus {
  display: none;
}

.mean-nav ul li a.mean-expand.mean-clicked i.fa-minus {
  display: block;
}

.mean-nav ul li a.mean-expand.mean-clicked i.fa-plus {
  display: none;
}

.mobile-navbar-menu .mean-bar * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.mobile-navbar-menu .mean-nav {
  background: transparent;
  overflow: hidden;
  margin-top: 15px;
  margin-bottom: 20px;
}

.mobile-navbar-menu .mean-nav > ul {
  display: block !important;
}

.mobile-navbar-menu .mean-nav ul li {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.mean-container .mean-nav ul {
  padding-left: 15px;
}

.mobile-navbar-menu .mean-nav ul li:first-child {
  border-top: none;
}

.mobile-navbar-menu .mean-nav ul li a {
  color: var(--tj-color-common-white);
  border-top: none;
  padding: 14px 0;
  width: 100%;
  font-family: var(--tj-ff-heading);
  font-weight: var(--tj-fw-regular);
}

.mean-container .mean-nav ul li li a {
  width: 100%;
  padding: 10px 0;
  text-shadow: none !important;
  visibility: visible;
}

.mean-container .mean-nav ul li li a {
  text-transform: capitalize;
}

.tj-main-menu > ul > li.has-dropdown > a {
  position: relative;
}

.tj-main-menu > ul > li.has-dropdown > a::before {
  content: "+";
  font-family: "Font Awesome 6 Pro";
  font-weight: 500;
  font-size: 13px;
  position: absolute;
  color: rgba(74, 50, 111, 0.5);
  top: 55%;
  transform: translateY(-50%);
  right: 0;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}

.tj-main-menu > ul > li.has-dropdown > a:hover:before {
  color: var(--tj-color-gradient-1);
}

.tj-main-menu > ul > li.has-dropdown > .active::before {
  color: var(--tj-color-gradient-1);
}

.tj-main-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
}
.tj-main-menu ul li {
  display: inline-block;
  position: relative;
  margin-right: 35px;
}
.tj-main-menu ul li.has-dropdown a {
  padding-right: 20px;
}
.tj-main-menu ul li:last-child {
  padding-right: 0;
}
.tj-main-menu ul li a {
  color: var(--tj-color-heading-primary);
  font-size: 16px;
  font-weight: var(--tj-fw-regular);
  font-family: var(--tj-ff-heading);
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
  padding: 15px 0;
}
.tj-main-menu ul li a.active, .tj-main-menu ul li a:hover {
  color: var(--tj-color-gradient-1);
}

.tj-main-menu li .sub-menu {
  text-align: left;
  margin: 0;
  list-style: none;
  background: var(--tj-color-common-white);
  position: absolute;
  opacity: 0;
  padding: 15px 0;
  visibility: hidden;
  z-index: 9;
  top: 130%;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  width: 250px;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}
.tj-main-menu li .sub-menu::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 0%;
  height: 3px;
  background: var(--tj-color-gradient-1);
  content: "";
  transition: 0.6s;
  -webkit-transition: 0.6s;
  -moz-transition: 0.6s;
}

.tj-main-menu li .sub-menu li {
  display: block;
  margin-right: 0;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}
.tj-main-menu li .sub-menu li a {
  position: relative;
  padding: 10px 20px;
  display: block;
}
.tj-main-menu li .sub-menu li a::before {
  content: "";
  width: 0;
  height: 2px;
  background: var(--tj-color-gradient-1);
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}
.tj-main-menu li .sub-menu li a.active::before, .tj-main-menu li .sub-menu li a:hover::before {
  width: 14px;
}
.tj-main-menu li .sub-menu li:last-child {
  padding-bottom: 0;
}
.tj-main-menu li .sub-menu li.active, .tj-main-menu li .sub-menu li:hover {
  padding-left: 15px;
}

.tj-main-menu li:hover .sub-menu {
  opacity: 1;
  visibility: visible;
  top: 150%;
}

.tj-main-menu li:hover .sub-menu::before {
  width: 100%;
}

.logo-area a:focus,
.logo-area a {
  outline: none;
}

.tj-main-menu > ul > li > a {
  text-transform: capitalize;
}

.menu-icon-toggle {
  display: block;
  width: 30px;
  height: 30px;
  position: relative;
  z-index: 9;
}
.home .menu-icon-toggle {
  top: 50px;
  position: absolute;
  right: 20px;
  top: 20px;
}
.menu-icon-toggle span {
  display: block;
  width: 100%;
  height: 2px;
  position: absolute;
  top: 50%;
  background-color: var(--tj-color-gradient-1);
  transform: translate(0, -50%);
  transition: opacity 0.3s 0.3s;
}
.home:not(.open) .menu-icon-toggle span {
  background-color: var(--tj-color-gradient-1);
}
.menu-icon-toggle::before, .menu-icon-toggle::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  position: absolute;
  background-color: var(--tj-color-gradient-1);
  transition: transform 0.3s, top 0.3s 0.3s, bottom 0.3s 0.3s;
}
.menu-icon-toggle::before {
  top: 6px;
}
.menu-icon-toggle::after {
  bottom: 6px;
}
.open .menu-icon-toggle span {
  opacity: 0;
  transition: opacity 0.3s;
}
.open .menu-icon-toggle::before, .open .menu-icon-toggle::after {
  background-color: var(--tj-color-gradient-1);
}
.open .menu-icon-toggle::before {
  top: calc(50% - 1px);
  transform: rotate(45deg);
}
.open .menu-icon-toggle::after {
  bottom: calc(50% - 1px);
  transform: rotate(-45deg);
}
.open .menu-icon-toggle::before, .open .menu-icon-toggle::after {
  transition: top 0.3s, bottom 0.3s, transform 0.3s 0.3s;
}

.tj-canva-icon a {
  cursor: pointer;
  display: block;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}
.tj-canva-icon a span {
  height: 2px;
  width: 30px;
  display: block;
  background: var(--tj-color-gradient-1);
  cursor: pointer;
  list-style: none;
  margin: 6px 0;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  margin-left: auto;
}

.offcanvas-icon i {
  font-size: 35px;
  color: var(--tj-color-common-white);
  transition: 0.4s;
}
.offcanvas-icon i:hover {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

@-webkit-keyframes criss-cross-left {
  0% {
    left: -20px;
  }
  50% {
    left: 50%;
    width: 20px;
    height: 20px;
  }
  100% {
    left: 50%;
    width: 375px;
    height: 375px;
  }
}
@keyframes criss-cross-left {
  0% {
    left: -20px;
  }
  50% {
    left: 50%;
    width: 20px;
    height: 20px;
  }
  100% {
    left: 50%;
    width: 375px;
    height: 375px;
  }
}
@-webkit-keyframes criss-cross-right {
  0% {
    right: -20px;
  }
  50% {
    right: 50%;
    width: 20px;
    height: 20px;
  }
  100% {
    right: 50%;
    width: 375px;
    height: 375px;
  }
}
@keyframes criss-cross-right {
  0% {
    right: -20px;
  }
  50% {
    right: 50%;
    width: 20px;
    height: 20px;
  }
  100% {
    right: 50%;
    width: 375px;
    height: 375px;
  }
}
/* !END: Mainmenu CSS */

/**----------------------------------------
START: Colloration CSS
----------------------------------------*/
.tj-collaboration-section {
  padding: 350px 0;
  position: relative;
}
.tj-collaboration-section .tj-sec-heading .title {
  max-width: 550px;
}
.tj-collaboration-section .collaboration-bg-image {
  position: absolute;
  left: 0;
  width: 70%;
  z-index: 1;
  height: 850px;
  top: 50%;
  transform: translateY(-50%);
}

.collaboration-shape {
  position: absolute;
  right: -5%;
  top: -45%;
}

.tj-collaboration-content {
  padding-left: 40px;
}
.tj-collaboration-content .desc {
  margin-bottom: 25px;
}
.tj-collaboration-content .check-list {
  margin-bottom: 35px;
}
.tj-collaboration-content .check-list ul li {
  margin-bottom: 15px;
  position: relative;
  font-weight: var(--tj-fw-sbold);
  font-family: var(--tj-ff-heading);
  font-size: 18px;
  padding-left: 30px;
  color: var(--tj-color-heading-primary);
}
.tj-collaboration-content .check-list ul li i {
  margin-right: 5px;
  position: absolute;
  top: 20px;
  transform: translateY(-50%);
  left: 0;
}

/* !END: Colloration CSS */
/**----------------------------------------
START: Communication CSS
----------------------------------------*/
.tj-communication-section {
  background: var(--tj-color-grey-6);
  padding-top: 110px;
  padding-bottom: 100px;
  position: relative;
}
.tj-communication-section .communication-shape {
  position: absolute;
  left: 35%;
  transform: translateX(-50%);
  bottom: 10%;
}

.tj-communication-content {
  margin-bottom: 55px;
}
.tj-communication-content .desc {
  margin-bottom: 40px;
}

.communication-image {
  position: relative;
  right: -50px;
  z-index: 9;
}

/* !END: Communication CSS */
/**----------------------------------------
START: Video CSS
----------------------------------------*/
.tj-video-section {
  background: url(../images/banner/bg-3.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 290px 0;
  position: relative;
}
.tj-video-section::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  background: linear-gradient(0deg, rgba(19, 0, 49, 0.7) 0%, rgba(19, 0, 49, 0.01) 100%);
}

.tj-video-area .video-icon {
  border: 1px solid rgba(255, 255, 255, 0.4);
  padding: 10px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
}
.tj-video-area .video-icon::before {
  position: absolute;
  content: "";
  width: 120px;
  height: 120px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 50%;
}
.tj-video-area .popup-videos-button {
  background: linear-gradient(90deg, var(--tj-color-gradient-1) 0%, var(--tj-color-gradient-3) 100%, var(--tj-color-gradient-2) 100%);
  width: 80px;
  height: 80px;
  line-height: 80px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  position: relative;
  z-index: 3;
}

.tj-video-area .pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.5);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}
/* !END: Video CSS */
/**----------------------------------------
START: Subcribe CSS
----------------------------------------*/
.tj-subcribe-section {
  position: relative;
  z-index: 3;
}

.tj-subcribe-page {
  padding-top: 120px;
}

.subcribe-content-area {
  padding: 60px 60px 50px;
  position: relative;
  z-index: 3;
}
.subcribe-content-area::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: 50px;
  background: linear-gradient(90deg, var(--tj-color-gradient-1) 0%, var(--tj-color-gradient-3) 100%, var(--tj-color-gradient-2) 100%);
}
.subcribe-content-area .title {
  color: var(--tj-color-common-white);
  max-width: 580px;
  margin: 0 auto;
}
.subcribe-content-area .subcribe-input-form {
  margin-top: 35px;
  margin-bottom: 25px;
  gap: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.subcribe-content-area .subcribe-input-form .subcribe-input input[type=email] {
  border-radius: 5px;
  width: 485px;
  padding: 19px 30px;
  border: 1px solid var(--tj-color-common-black-2);
}
.subcribe-content-area .subcribe-list ul li {
  display: inline-block;
  color: var(--tj-color-common-white);
  padding-left: 25px;
  margin-right: 15px;
  margin-bottom: 15px;
  position: relative;
}
.subcribe-content-area .subcribe-list ul li i {
  margin-right: 5px;
  position: absolute;
  top: 60%;
  transform: translateY(-50%);
  left: 0;
}

.subcribe-input-form input::placeholder {
  color: var(--tj-color-grey-1);
  opacity: 1;
}

.subcribe-input-form input:-ms-input-placeholder {
  color: var(--tj-color-grey-1);
}

.subcribe-input-form input::-ms-input-placeholder {
  color: var(--tj-color-grey-1);
}

/* !END: Subcribe CSS */
/**----------------------------------------
START: Theme Scroll CSS
----------------------------------------*/
.saasify-scroll-top {
  position: fixed;
  right: 30px;
  bottom: 25px;
  width: 45px;
  height: 45px;
  cursor: pointer;
  display: block;
  border-radius: 100%;
  box-shadow: inset 0 0 0 0.1rem rgba(128, 130, 134, 0.25);
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  transform: translateY(0.75rem);
  transition: all 0.2s linear, margin-right 0s;
}

.saasify-scroll-top.progress-done {
  visibility: visible;
  transform: translateY(0);
}

.saasify-scroll-top-icon {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: var(--tj-color-gradient-3);
}

.saasify-scroll-top > svg path {
  fill: none;
}

.saasify-scroll-top > svg.progress-circle path {
  stroke: var(--tj-color-gradient-3);
  stroke-width: 4;
  box-sizing: border-box;
  transition: all 200ms linear;
}

@media (min-width: 1px) {
  .saasify-scroll-top.progress-done {
    opacity: 1;
  }
}
@-webkit-keyframes letters-loading {
  0%, 75%, 100% {
    opacity: 0;
    -webkit-transform: rotateY(-90deg);
    transform: rotateY(-90deg);
  }
  25%, 50% {
    opacity: 1;
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
}
@keyframes letters-loading {
  0%, 75%, 100% {
    opacity: 0;
    -webkit-transform: rotateY(-90deg);
    transform: rotateY(-90deg);
  }
  25%, 50% {
    opacity: 1;
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
}
/* !END: Theme Scroll CSS */
/**----------------------------------------
START: Circle CSS
----------------------------------------*/
.tj-hero-section {
  position: relative;
}

.tj-circle-box .circle-1 {
  position: absolute;
  width: 10px;
  height: 10px;
  left: 4%;
  top: 30%;
  background: var(--tj-color-extra-2);
  border-radius: 50%;
  animation: services-triangle 6s linear infinite alternate;
}
.tj-circle-box .circle-2 {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 12%;
  top: 35%;
  background: var(--tj-color-extra-4);
  border-radius: 50%;
  animation: services-triangle 8s linear infinite alternate;
}
.tj-circle-box .circle-3 {
  position: absolute;
  width: 18px;
  height: 18px;
  left: 16%;
  top: 20%;
  background: var(--tj-color-extra-3);
  border-radius: 50%;
  animation: services-triangle 10s linear infinite alternate;
}
.tj-circle-box .circle-4 {
  position: absolute;
  width: 10px;
  height: 10px;
  left: 20%;
  top: 12%;
  background: var(--tj-color-extra-1);
  border-radius: 50%;
  animation: services-triangle 9s linear infinite alternate;
}
.tj-circle-box .circle-5 {
  position: absolute;
  width: 34px;
  height: 34px;
  right: 10%;
  top: 12%;
  background: var(--tj-color-extra-1);
  border-radius: 50%;
  animation: services-triangle 6s linear infinite alternate;
}
.tj-circle-box .circle-6 {
  position: absolute;
  width: 10px;
  height: 10px;
  right: 8%;
  top: 25%;
  background: var(--tj-color-extra-2);
  border-radius: 50%;
  animation: services-triangle 7s linear infinite alternate;
}
.tj-circle-box .circle-7 {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 12%;
  top: 32%;
  background: var(--tj-color-extra-4);
  border-radius: 50%;
  animation: services-triangle 11s linear infinite alternate;
}
.tj-circle-box .circle-8 {
  position: absolute;
  width: 6px;
  height: 6px;
  right: 20%;
  top: 27%;
  background: var(--tj-color-extra-3);
  border-radius: 50%;
  animation: services-triangle 9s linear infinite alternate;
}

.tj-circle-box2 {
  position: absolute;
  right: 4%;
  top: 20%;
  width: 160px;
  height: 160px;
}
.tj-circle-box2 .circle-1 {
  position: absolute;
  width: 8px;
  height: 8px;
  left: 30%;
  top: 35%;
  background: var(--tj-color-extra-2);
  border-radius: 50%;
  animation: services-triangle 7s linear infinite alternate;
}
.tj-circle-box2 .circle-2 {
  position: absolute;
  width: 8px;
  height: 8px;
  left: 10%;
  top: 22%;
  background: var(--tj-color-extra-4);
  border-radius: 50%;
  animation: services-triangle 8s linear infinite alternate;
}
.tj-circle-box2 .circle-3 {
  position: absolute;
  width: 8px;
  height: 8px;
  left: -24%;
  top: 30%;
  background: var(--tj-color-extra-3);
  border-radius: 50%;
  animation: services-triangle 9s linear infinite alternate;
}
.tj-circle-box2 .circle-4 {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 45%;
  top: 12%;
  background: var(--tj-color-extra-1);
  border-radius: 50%;
  animation: services-triangle 10s linear infinite alternate;
}

.tj-circle-box3 {
  position: absolute;
  right: 20%;
  width: 160px;
  height: 160px;
  bottom: 13%;
}
.tj-circle-box3 .circle-1 {
  position: absolute;
  width: 8px;
  height: 8px;
  left: -5%;
  top: 15%;
  background: var(--tj-color-extra-2);
  border-radius: 50%;
  animation: services-triangle 9s linear infinite alternate;
}
.tj-circle-box3 .circle-2 {
  position: absolute;
  width: 8px;
  height: 8px;
  left: 20%;
  top: 30%;
  background: var(--tj-color-extra-4);
  border-radius: 50%;
  animation: services-triangle 10s linear infinite alternate;
}
.tj-circle-box3 .circle-3 {
  position: absolute;
  width: 8px;
  height: 8px;
  left: 60%;
  top: 20%;
  background: var(--tj-color-extra-3);
  border-radius: 50%;
  animation: services-triangle 11s linear infinite alternate;
}
.tj-circle-box3 .circle-4 {
  position: absolute;
  width: 20px;
  height: 20px;
  left: -30%;
  top: 28%;
  background: var(--tj-color-extra-1);
  border-radius: 50%;
  animation: services-triangle 8s linear infinite alternate;
}

.tj-circle-box3 {
  position: absolute;
  right: 20%;
  width: 160px;
  height: 160px;
  bottom: 13%;
}
.tj-circle-box3 .circle-1 {
  position: absolute;
  width: 8px;
  height: 8px;
  left: -5%;
  top: 15%;
  background: var(--tj-color-extra-2);
  border-radius: 50%;
}
.tj-circle-box3 .circle-2 {
  position: absolute;
  width: 8px;
  height: 8px;
  left: 20%;
  top: 30%;
  background: var(--tj-color-extra-4);
  border-radius: 50%;
}
.tj-circle-box3 .circle-3 {
  position: absolute;
  width: 8px;
  height: 8px;
  left: 60%;
  top: 20%;
  background: var(--tj-color-extra-3);
  border-radius: 50%;
}
.tj-circle-box3 .circle-4 {
  position: absolute;
  width: 20px;
  height: 20px;
  left: -30%;
  top: 28%;
  background: var(--tj-color-extra-1);
  border-radius: 50%;
}

.tj-circle-box5,
.tj-circle-box4 {
  position: absolute;
  right: 20%;
  width: 160px;
  height: 160px;
  bottom: 13%;
  animation: services-triangle 9s linear infinite alternate;
}
.tj-circle-box5 .circle-1,
.tj-circle-box4 .circle-1 {
  position: absolute;
  width: 8px;
  height: 8px;
  left: -5%;
  top: 15%;
  background: var(--tj-color-common-white);
  border-radius: 50%;
  animation: services-triangle 12s linear infinite alternate;
}
.tj-circle-box5 .circle-2,
.tj-circle-box4 .circle-2 {
  position: absolute;
  width: 8px;
  height: 8px;
  left: 20%;
  top: 30%;
  background: var(--tj-color-common-white);
  border-radius: 50%;
  animation: services-triangle 10s linear infinite alternate;
}
.tj-circle-box5 .circle-3,
.tj-circle-box4 .circle-3 {
  position: absolute;
  width: 8px;
  height: 8px;
  left: 60%;
  top: 20%;
  background: var(--tj-color-common-white);
  border-radius: 50%;
  animation: services-triangle 13s linear infinite alternate;
}
.tj-circle-box5 .circle-4,
.tj-circle-box4 .circle-4 {
  position: absolute;
  width: 20px;
  height: 20px;
  left: -30%;
  top: 28%;
  background: var(--tj-color-common-white);
  border-radius: 50%;
  animation: services-triangle 8s linear infinite alternate;
}

.tj-circle-box6 .circle-1 {
  position: absolute;
  width: 34px;
  height: 34px;
  right: 9%;
  top: 22%;
  background: var(--tj-color-extra-1);
  border-radius: 50%;
  animation: services-triangle 13s linear infinite alternate;
}
.tj-circle-box6 .circle-2 {
  position: absolute;
  width: 8px;
  height: 8px;
  right: 7%;
  top: 40%;
  background: var(--tj-color-extra-2);
  border-radius: 50%;
  animation: services-triangle 10s linear infinite alternate;
}
.tj-circle-box6 .circle-4 {
  position: absolute;
  width: 10px;
  height: 10px;
  left: 8%;
  top: 28%;
  background: var(--tj-color-extra-1);
  border-radius: 50%;
  animation: services-triangle 16s linear infinite alternate;
}
.tj-circle-box6 .circle-5 {
  position: absolute;
  width: 10px;
  height: 10px;
  left: 10%;
  top: 50%;
  background: var(--tj-color-extra-2);
  border-radius: 50%;
  animation: services-triangle 15s linear infinite alternate;
}
.tj-circle-box6 .circle-6 {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 5%;
  top: 65%;
  background: var(--tj-color-extra-4);
  border-radius: 50%;
  animation: services-triangle 11s linear infinite alternate;
}

.tj-progress-section .tj-circle-box3 {
  position: absolute;
  left: 30%;
  width: 160px;
  height: 160px;
  bottom: 13%;
}

.tj-testimonial-section .tj-circle-box3 {
  position: absolute;
  right: 14%;
  width: 160px;
  height: 160px;
  top: 12%;
}

.tj-contact-section .tj-circle-box3 {
  position: absolute;
  right: 20%;
  width: 160px;
  height: 160px;
  bottom: 20%;
}

.tj-circle-box5 {
  position: absolute;
  left: 8%;
  top: 50%;
  transform: translateY(-50%);
  width: 160px;
  height: 160px;
}

.tj-footer-area {
  position: relative;
}
.tj-footer-area .tj-circle-box .circle-1 {
  left: 5%;
  top: 80%;
}
.tj-footer-area .tj-circle-box .circle-2 {
  right: 2%;
  top: 80%;
}
.tj-footer-area .tj-circle-box .circle-4 {
  left: 5%;
  top: 55%;
}
.tj-footer-area .tj-circle-box .circle-5 {
  right: 10%;
  top: 42%;
}
.tj-footer-area .tj-circle-box .circle-6 {
  right: 10%;
  top: 70%;
}
.tj-footer-area .tj-circle-box .circle-7 {
  right: 7%;
  top: 75%;
}
.tj-footer-area .tj-circle-box .circle-8 {
  right: 10%;
  top: 70%;
}

.tj-hero-section-two .tj-star-box .star-1 {
  position: absolute;
  left: 11%;
  top: 8%;
  z-index: 3;
  animation: rotateImg 15s infinite linear;
}
.tj-hero-section-two .tj-star-box .star-2 {
  position: absolute;
  top: 37%;
  left: 15%;
  animation-delay: 2s;
  animation: rotateImg 15s infinite linear;
}
.tj-hero-section-two .tj-star-box .star-3 {
  position: absolute;
  bottom: 20%;
  left: 5%;
  height: 20px;
  animation: rotateImg 15s infinite linear;
}
.tj-hero-section-two .tj-star-box .star-4 {
  position: absolute;
  top: 12%;
  right: 8%;
  animation: rotateImg 15s infinite linear;
}
.tj-hero-section-two .tj-star-box .star-5 {
  position: absolute;
  top: 20%;
  right: 3%;
  animation: rotateImg 15s infinite linear;
}
.tj-hero-section-two .tj-star-box .star-6 {
  position: absolute;
  top: 15%;
  right: 22%;
  animation: rotateImg 15s infinite linear;
}
.tj-hero-section-two .tj-star-box .star-7 {
  position: absolute;
  top: 35%;
  right: 25%;
  animation: rotateImg 15s infinite linear;
}
.tj-hero-section-two .tj-star-box .star-8 {
  position: absolute;
  top: 60%;
  right: 10%;
  animation: rotateImg 15s infinite linear;
}

@keyframes rotateImg {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }
  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}
.tj-star-box .star-1 {
  position: absolute;
  left: 11%;
  top: 11%;
  z-index: 3;
  animation: rotateImg 15s infinite linear;
}
.tj-star-box .star-2 {
  position: absolute;
  top: 47%;
  left: 15%;
  animation: rotateImg 15s infinite linear;
}
.tj-star-box .star-3 {
  position: absolute;
  bottom: 20%;
  left: 5%;
  animation: rotateImg 15s infinite linear;
}
.tj-star-box .star-4 {
  position: absolute;
  top: 12%;
  right: 8%;
  animation: rotateImg 15s infinite linear;
}
.tj-star-box .star-5 {
  position: absolute;
  top: 20%;
  right: 3%;
  animation: rotateImg 15s infinite linear;
}
.tj-star-box .star-6 {
  position: absolute;
  top: 15%;
  right: 22%;
  animation: rotateImg 15s infinite linear;
}
.tj-star-box .star-7 {
  position: absolute;
  top: 35%;
  right: 25%;
  animation: rotateImg 15s infinite linear;
}
.tj-star-box .star-8 {
  position: absolute;
  top: 54%;
  right: 10%;
  animation: rotateImg 15s infinite linear;
}

.tj-star-group {
  position: absolute;
  right: 22%;
  top: 8%;
  width: 80px;
  height: 100px;
}
.tj-star-group .icon-1 {
  animation: rotateImg 15s infinite linear;
}
.tj-star-group .icon-2 {
  position: absolute;
  top: 25px;
  right: 0;
  animation: rotateImg 15s infinite linear;
}
.tj-star-group .icon-3 {
  position: absolute;
  left: 0;
  bottom: 0;
  animation: rotateImg 15s infinite linear;
}
.tj-star-group .icon-4 {
  position: absolute;
  bottom: 0;
  right: 20px;
  animation: rotateImg 15s infinite linear;
}

.tj-star-transparent-group {
  position: absolute;
  width: 80px;
  height: 100px;
  left: 35%;
  bottom: 25%;
}
.tj-star-transparent-group .icon-1 {
  animation: rotateImg 15s infinite linear;
}
.tj-star-transparent-group .icon-2 {
  position: absolute;
  top: 25px;
  right: 0;
  animation: rotateImg 15s infinite linear;
}
.tj-star-transparent-group .icon-3 {
  position: absolute;
  left: -10px;
  bottom: 0;
  animation: rotateImg 15s infinite linear;
}
.tj-star-transparent-group .icon-4 {
  position: absolute;
  bottom: 0;
  right: 20px;
  animation: rotateImg 15s infinite linear;
}

.tj-about-section-two .tj-star-group {
  position: absolute;
  right: 22%;
  top: 8%;
}
.tj-about-section-two .tj-star-group-1 {
  position: absolute;
  top: 20%;
  left: 5%;
}
.tj-about-section-two .tj-star-group-2 {
  position: absolute;
  bottom: 10%;
  left: 25%;
  top: auto;
}

.tj-feature-section .tj-star-group {
  position: absolute;
  top: 25%;
  left: 16%;
}
.tj-feature-section .tj-star-group-1 {
  position: absolute;
  top: 40%;
  right: 5%;
  left: auto;
}

.tj-choose-us-section .tj-star-group {
  position: absolute;
  top: 13%;
  left: 43%;
}
.tj-choose-us-section .tj-star-group-1 {
  position: absolute;
  bottom: 25%;
  right: 25%;
  left: auto;
  top: auto;
}
.tj-choose-us-section .tj-star-group-2 {
  position: absolute;
  bottom: 20%;
  left: 10%;
  top: auto;
}

.tj-cta-section-two .tj-star-group {
  position: absolute;
  top: 15%;
  left: 8%;
}

.tj-blog-section .tj-star-group {
  position: absolute;
  top: 18%;
  left: 10%;
}
.tj-blog-section .tj-star-group-1 {
  position: absolute;
  right: 6%;
  top: auto;
  bottom: 15%;
  left: auto;
}

.tj-price-section-two .tj-star-group {
  position: absolute;
  right: 7%;
  top: 25%;
}
.tj-price-section-two .tj-star-group-1 {
  position: absolute;
  right: auto;
  top: auto;
  left: 4%;
  bottom: 18%;
}

.tj-faq-section .tj-star-group {
  position: absolute;
  left: 10%;
  top: auto;
  bottom: 25%;
}

.tj-circle-box-one {
  width: 510px;
  height: 135px;
  display: inline-block;
}
.tj-circle-box-one .circle_1 {
  position: absolute;
  top: -12%;
  left: 3%;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  display: inline-block;
  background: var(--tj-color-extra-3);
}
.tj-circle-box-one .circle_1::before {
  position: absolute;
  content: "";
  top: 50%;
  transform: translateY(-50%);
  width: 120px;
  height: 1px;
  left: 15px;
  border: 1px dashed var(--tj-color-light-6);
}
.tj-circle-box-one .circle_2 {
  position: absolute;
  top: 10%;
  left: 10%;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  display: inline-block;
  background: var(--tj-color-extra-2);
}
.tj-circle-box-one .circle_2::before {
  position: absolute;
  content: "";
  top: 50%;
  transform: translateY(-50%);
  width: 120px;
  height: 1px;
  left: 15px;
  border: 1px dashed var(--tj-color-light-6);
}
.tj-circle-box-one .circle_3 {
  position: absolute;
  top: 30%;
  left: 35%;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  display: inline-block;
  background: var(--tj-color-extra-4);
}
.tj-circle-box-one .circle_3::before {
  position: absolute;
  content: "";
  top: 50%;
  transform: translateY(-50%);
  width: 120px;
  height: 1px;
  left: 15px;
  border: 1px dashed var(--tj-color-light-6);
}
.tj-circle-box-one .circle_4 {
  position: absolute;
  top: 50%;
  left: 30%;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  display: inline-block;
  background: var(--tj-color-extra-3);
}
.tj-circle-box-one .circle_4::before {
  position: absolute;
  content: "";
  top: 50%;
  transform: translateY(-50%);
  width: 120px;
  height: 1px;
  left: 15px;
  border: 1px dashed var(--tj-color-light-6);
}
.tj-circle-box-one .circle_5 {
  position: absolute;
  top: 70%;
  left: 25%;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  display: inline-block;
  background: var(--tj-color-extra-2);
}
.tj-circle-box-one .circle_5::before {
  position: absolute;
  content: "";
  top: 50%;
  transform: translateY(-50%);
  width: 120px;
  height: 1px;
  left: 15px;
  border: 1px dashed var(--tj-color-light-6);
}
.tj-circle-box-one .circle_6 {
  position: absolute;
  top: 10%;
  left: -25%;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  display: inline-block;
  background: var(--tj-color-extra-4);
}
.tj-circle-box-one .circle_6::before {
  position: absolute;
  content: "";
  top: 50%;
  transform: translateY(-50%);
  width: 120px;
  height: 1px;
  left: 15px;
  border: 1px dashed var(--tj-color-light-6);
}
.tj-circle-box-one .circle_7 {
  position: absolute;
  top: 30%;
  left: -18%;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  display: inline-block;
  background: var(--tj-color-extra-4);
}
.tj-circle-box-one .circle_7::before {
  position: absolute;
  content: "";
  top: 50%;
  transform: translateY(-50%);
  width: 120px;
  height: 1px;
  left: 15px;
  border: 1px dashed var(--tj-color-light-6);
}
.tj-circle-box-one .circle_8 {
  position: absolute;
  top: 48%;
  left: -10%;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  display: inline-block;
  background: var(--tj-color-extra-4);
}
.tj-circle-box-one .circle_8::before {
  position: absolute;
  content: "";
  top: 50%;
  transform: translateY(-50%);
  width: 120px;
  height: 1px;
  left: 15px;
  border: 1px dashed var(--tj-color-light-6);
}
.tj-circle-box-one .circle_9 {
  position: absolute;
  top: 50%;
  left: -30%;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  display: inline-block;
  background: var(--tj-color-extra-2);
}
.tj-circle-box-one .circle_9::before {
  position: absolute;
  content: "";
  top: 50%;
  transform: translateY(-50%);
  width: 70px;
  height: 1px;
  left: 15px;
  border: 1px dashed var(--tj-color-light-6);
}

.tj-circle-box-one-1 {
  width: 510px;
  height: 135px;
  display: inline-block;
}
.tj-circle-box-one-1 .circle_1 {
  position: absolute;
  top: -12%;
  left: 3%;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  display: inline-block;
  background: var(--tj-color-extra-3);
}
.tj-circle-box-one-1 .circle_1::before {
  position: absolute;
  content: "";
  top: 50%;
  transform: translateY(-50%);
  width: 120px;
  height: 1px;
  right: 15px;
  left: auto;
  border: 1px dashed var(--tj-color-light-6);
}
.tj-circle-box-one-1 .circle_2 {
  position: absolute;
  top: 10%;
  left: 10%;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  display: inline-block;
  background: var(--tj-color-extra-2);
}
.tj-circle-box-one-1 .circle_2::before {
  position: absolute;
  content: "";
  top: 50%;
  transform: translateY(-50%);
  width: 120px;
  height: 1px;
  right: 15px;
  left: auto;
  border: 1px dashed var(--tj-color-light-6);
}
.tj-circle-box-one-1 .circle_3 {
  position: absolute;
  top: 30%;
  left: 35%;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  display: inline-block;
  background: var(--tj-color-extra-4);
}
.tj-circle-box-one-1 .circle_3::before {
  position: absolute;
  content: "";
  top: 50%;
  transform: translateY(-50%);
  width: 120px;
  height: 1px;
  right: 15px;
  left: auto;
  border: 1px dashed var(--tj-color-light-6);
}
.tj-circle-box-one-1 .circle_4 {
  position: absolute;
  top: 50%;
  left: 30%;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  display: inline-block;
  background: var(--tj-color-extra-4);
}
.tj-circle-box-one-1 .circle_4::before {
  position: absolute;
  content: "";
  top: 50%;
  transform: translateY(-50%);
  width: 120px;
  height: 1px;
  right: 15px;
  left: auto;
  border: 1px dashed var(--tj-color-light-6);
}
.tj-circle-box-one-1 .circle_5 {
  position: absolute;
  top: 70%;
  left: 25%;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  display: inline-block;
  background: var(--tj-color-extra-4);
}
.tj-circle-box-one-1 .circle_5::before {
  position: absolute;
  content: "";
  top: 50%;
  transform: translateY(-50%);
  width: 120px;
  height: 1px;
  right: 15px;
  left: auto;
  border: 1px dashed var(--tj-color-light-6);
}
.tj-circle-box-one-1 .circle_6 {
  position: absolute;
  top: 10%;
  left: -25%;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  display: inline-block;
  background: var(--tj-color-extra-2);
}
.tj-circle-box-one-1 .circle_6::before {
  position: absolute;
  content: "";
  top: 50%;
  transform: translateY(-50%);
  width: 120px;
  height: 1px;
  right: 15px;
  left: auto;
  border: 1px dashed var(--tj-color-light-6);
}
.tj-circle-box-one-1 .circle_7 {
  position: absolute;
  top: 30%;
  left: -18%;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  display: inline-block;
  background: var(--tj-color-extra-3);
}
.tj-circle-box-one-1 .circle_7::before {
  position: absolute;
  content: "";
  top: 50%;
  transform: translateY(-50%);
  width: 120px;
  height: 1px;
  right: 15px;
  left: auto;
  border: 1px dashed var(--tj-color-light-6);
}
.tj-circle-box-one-1 .circle_8 {
  position: absolute;
  top: 48%;
  left: -10%;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  display: inline-block;
  background: var(--tj-color-extra-4);
}
.tj-circle-box-one-1 .circle_8::before {
  position: absolute;
  content: "";
  top: 50%;
  transform: translateY(-50%);
  width: 120px;
  height: 1px;
  right: 15px;
  left: auto;
  border: 1px dashed var(--tj-color-light-6);
}
.tj-circle-box-one-1 .circle_9 {
  position: absolute;
  top: 50%;
  left: -30%;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  display: inline-block;
  background: var(--tj-color-extra-2);
}
.tj-circle-box-one-1 .circle_9::before {
  position: absolute;
  content: "";
  top: 50%;
  transform: translateY(-50%);
  width: 70px;
  height: 1px;
  right: 15px;
  left: auto;
  border: 1px dashed var(--tj-color-light-6);
}

.tj-circle-box-one-2 {
  width: 250px;
  height: 90px;
  display: inline-block;
}
.tj-circle-box-one-2 .circle_1 {
  position: absolute;
  top: -12%;
  left: 3%;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  display: inline-block;
  background: var(--tj-color-extra-3);
  animation: services-triangle 8s linear infinite alternate;
}
.tj-circle-box-one-2 .circle_2 {
  position: absolute;
  top: 10%;
  left: 10%;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
  background: var(--tj-color-extra-2);
  animation: services-triangle 10s linear infinite alternate;
}
.tj-circle-box-one-2 .circle_3 {
  position: absolute;
  top: 30%;
  left: 35%;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  display: inline-block;
  background: var(--tj-color-extra-4);
  animation: services-triangle 9s linear infinite alternate;
}
.tj-circle-box-one-2 .circle_4 {
  position: absolute;
  top: 50%;
  left: 30%;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  display: inline-block;
  background: var(--tj-color-extra-3);
  animation: services-triangle 12s linear infinite alternate;
}
.tj-circle-box-one-2 .circle_5 {
  position: absolute;
  top: 70%;
  left: 25%;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  display: inline-block;
  background: var(--tj-color-extra-2);
  animation: services-triangle 7s linear infinite alternate;
}
.tj-circle-box-one-2 .circle_6 {
  position: absolute;
  top: 10%;
  left: -25%;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  background: var(--tj-color-extra-4);
  animation: services-triangle 13s linear infinite alternate;
}
.tj-circle-box-one-2 .circle_7 {
  position: absolute;
  top: 30%;
  left: -18%;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  background: var(--tj-color-extra-4);
  animation: services-triangle 6s linear infinite alternate;
}
.tj-circle-box-one-2 .circle_8 {
  position: absolute;
  top: 48%;
  left: -10%;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  background: var(--tj-color-extra-4);
  animation: services-triangle 10s linear infinite alternate;
}
.tj-circle-box-one-2 .circle_9 {
  position: absolute;
  top: 50%;
  left: -30%;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  display: inline-block;
  background: var(--tj-color-extra-2);
  animation: services-triangle 14s linear infinite alternate;
}

.tj-slider-section .tj-circle-box-one {
  position: absolute;
  top: 15%;
  right: -10%;
}
.tj-slider-section .tj-circle-box-one-1 {
  position: absolute;
  top: auto;
  right: auto;
  bottom: 12%;
  left: 10%;
}

.tj-solution-section .tj-circle-box-one-2 {
  position: absolute;
  top: 15%;
  left: 10%;
}

.tj-collaboration-section .tj-circle-box-one-2 {
  position: absolute;
  right: 28%;
  bottom: 15%;
}

.tj-hero-section-three .tj-star-box .star-2 {
  bottom: 35%;
  position: absolute;
  top: auto;
  left: 12%;
}
.tj-hero-section-three .tj-star-box .star-3 {
  position: absolute;
  top: 16%;
  right: 40%;
}
.tj-hero-section-three .tj-star-box .star-5 {
  position: absolute;
  top: 18%;
  right: 38%;
}
.tj-hero-section-three .tj-star-box .star-6 {
  position: absolute;
  top: 13%;
  right: 25%;
}
.tj-hero-section-three .tj-star-box .star-7 {
  position: absolute;
  right: 43%;
  bottom: 20%;
  top: auto;
}

.tj-testimonial-slider-section .tj-circle-box-one {
  width: 510px;
  height: 135px;
  display: inline-block;
  position: absolute;
  right: -15%;
  top: 18%;
}

.tj-blog-page .tj-star-group {
  position: absolute;
  right: 10%;
  top: 50%;
}
.tj-blog-page .tj-star-group-1 {
  position: absolute;
  top: 8%;
  left: 18%;
}

.tj-blog-details .tj-star-group {
  position: absolute;
  left: 5%;
  top: 14%;
}
.tj-blog-details .star-group-2 {
  position: absolute;
  right: 10%;
  left: auto;
  top: 20%;
}
.tj-blog-details .star-group-3 {
  position: absolute;
  right: auto;
  left: 8%;
  top: 31%;
}
.tj-blog-details .star-group-4 {
  position: absolute;
  right: 32%;
  left: auto;
  top: 42%;
}
.tj-blog-details .star-group-5 {
  position: absolute;
  right: auto;
  left: 10%;
  top: 48%;
}
.tj-blog-details .star-group-6 {
  position: absolute;
  right: 28%;
  left: auto;
  top: 63%;
}
.tj-blog-details .details-overly {
  position: absolute;
  right: 0;
  top: 3%;
}
.tj-blog-details .details-overly-2 {
  position: absolute;
  right: auto;
  left: 0;
  top: 10%;
}
.tj-blog-details .details-overly-3 {
  position: absolute;
  right: 0;
  left: auto;
  top: 22%;
}
.tj-blog-details .details-overly-4 {
  position: absolute;
  right: 0;
  left: auto;
  top: 40%;
}
.tj-blog-details .details-overly-5 {
  position: absolute;
  right: 0;
  left: auto;
  top: 60%;
}
.tj-blog-details .details-overly-6 {
  position: absolute;
  right: auto;
  left: 0;
  top: 30%;
}
.tj-blog-details .details-overly-7 {
  position: absolute;
  right: auto;
  left: 0;
  top: auto;
  bottom: 5%;
}

/* !END: Circle CSS */
/**----------------------------------------
START: Feature CSS
----------------------------------------*/
.tj-feature-section {
  padding-top: 110px;
  padding-bottom: 60px;
  position: relative;
  overflow: hidden;
}
.tj-feature-section .tj-sec-heading-two {
  margin-bottom: 80px;
}
.tj-feature-section .tj-sec-heading-two .title {
  max-width: 850px;
  margin: auto;
}
.tj-feature-section .feature-overly {
  position: absolute;
  top: 0;
  left: 0;
}
.tj-feature-section .feature-overly-1 {
  position: absolute;
  top: 0;
  right: 0;
}

.tj-feature-item {
  min-height: 280px;
  border-radius: 10px;
  border: 1px solid var(--tj-color-light-6);
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
  margin-bottom: 60px;
  margin-top: 0;
  position: relative;
  z-index: 3;
}
.tj-feature-item .feature-icon {
  position: relative;
  margin-left: 30px;
  margin-top: -30px;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 68px;
  text-align: center;
  font-size: 35px;
  border-radius: 8px;
  border: 1px solid var(--tj-color-theme-primary);
  background-color: var(--tj-color-common-white);
  z-index: 3;
}
.tj-feature-item .feature-icon::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 8px;
  left: 0;
  top: 0;
  visibility: hidden;
  z-index: -1;
  opacity: 0;
  background: var(--gradients-color-gd-1, linear-gradient(90deg, var(--tj-color-gradient-1) 0%, var(--tj-color-gradient-3) 100%, var(--tj-color-gradient-2) 100%));
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}
.tj-feature-item .feature-content {
  padding: 30px 30px;
}
.tj-feature-item .feature-content .title-link {
  color: var(--tj-color-common-black-3);
}
.tj-feature-item .feature-content .desc {
  margin-bottom: 0;
}
.tj-feature-item:hover {
  border-color: 1px solid var(--tj-color-gradient-1);
  background: var(--tj-color-common-white);
  box-shadow: 15px 9px 30px 0px rgba(117, 77, 233, 0.15);
  margin-top: -25px;
}
.tj-feature-item:hover .feature-content .title-link {
  background: var(--gradients-color-gd-1, linear-gradient(90deg, var(--tj-color-gradient-1) 0%, var(--tj-color-gradient-3) 100%, var(--tj-color-gradient-2) 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.tj-feature-item:hover .feature-icon {
  border-color: transparent;
  color: var(--tj-color-common-white);
}
.tj-feature-item:hover .feature-icon img {
  filter: invert(1);
}
.tj-feature-item:hover .feature-icon::before {
  opacity: 1;
  visibility: visible;
}

.feature-section-two {
  padding-top: 110px;
  padding-bottom: 120px;
  position: relative;
}
.feature-section-two .feature-text {
  border-radius: 55px;
  background: var(--tj-color-light-5);
  color: var(--tj-color-heading-primary);
  position: absolute;
  top: -25%;
  padding: 10px 15px;
  left: 33%;
}
.feature-section-two .feature-text i {
  position: relative;
  top: 3px;
  margin-right: 5px;
}
.feature-section-two .feature-text-two {
  border-radius: 55px;
  background: var(--tj-color-light-1);
  color: var(--tj-color-common-white);
  position: absolute;
  top: 30%;
  padding: 10px 15px;
  right: -12%;
  z-index: 3;
}
.feature-section-two .feature-text-two i {
  position: relative;
  top: 3px;
  margin-right: 5px;
}
.feature-section-two .feature-shape {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.feature-section-two .feature-shape1 {
  position: absolute;
  right: 0;
  top: 16%;
  transform: translateY(-50%);
}
.feature-section-two .tj-sec-heading .title {
  max-width: 450px;
}

.feature-content-box {
  position: relative;
  z-index: 3;
}
.feature-content-box .tj-sec-heading {
  margin-bottom: 60px;
}
.feature-content-box .desc {
  max-width: 670px;
}

.feature-item-two {
  padding: 40px 40px;
  margin-bottom: 30px;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
  position: relative;
}
.feature-item-two::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 0 20px 20px 20px;
  opacity: 0;
  visibility: hidden;
  background: linear-gradient(119deg, var(--tj-color-gradient-1) 1.65%, var(--tj-color-gradient-3) 126.29%, var(--tj-color-gradient-2) 126.29%);
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}
.feature-item-two.margin_top {
  margin-top: 30px;
  margin-bottom: 0;
}
.feature-item-two .feature-content {
  position: relative;
  z-index: 3;
}
.feature-item-two.hover-shape-border {
  position: relative;
  border-radius: 0 20px 20px 20px;
  background: var(--tj-color-common-white);
  box-shadow: 15px 9px 30px 0px rgba(19, 0, 49, 0.03);
}
.feature-item-two .border-shadow {
  content: "";
  display: block;
  position: absolute;
  z-index: 9;
}
.feature-item-two .feature-item-shape .shadow-1 {
  top: 0px;
  left: 0;
  background: linear-gradient(to bottom, var(--tj-color-gradient-1) -100%, rgba(21, 22, 37, 0) 64%);
  width: 1.5px;
  height: 280px;
}
.feature-item-two .feature-item-shape .shadow-2 {
  top: 0;
  left: 0px;
  background: linear-gradient(to right, var(--tj-color-gradient-1) -100%, rgba(21, 22, 37, 0) 64%);
  width: 350px;
  height: 1.5px;
}
.feature-item-two .feature-icon {
  margin-bottom: 25px;
  position: relative;
  z-index: 3;
}
.feature-item-two .feature-icon i {
  width: 80px;
  height: 80px;
  line-height: 90px;
  text-align: center;
  display: inline-block;
  border-radius: 10px;
  font-size: 45px;
  color: var(--tj-color-gradient-1);
  border: 1.5px solid var(--tj-color-gradient-1);
}
.feature-item-two .title {
  margin-bottom: 20px;
  background: linear-gradient(90deg, var(--tj-color-gradient-1) 0%, var(--tj-color-gradient-3) 100%, var(--tj-color-gradient-2) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.feature-item-two .desc {
  margin-bottom: 0;
}
.feature-item-two:hover::before {
  opacity: 1;
  visibility: visible;
}
.feature-item-two:hover .feature-icon i {
  border: 1.5px solid var(--tj-color-common-white);
  color: var(--tj-color-common-white);
}
.feature-item-two:hover .title {
  background: linear-gradient(90deg, var(--tj-color-common-white) 0%, var(--tj-color-common-white) 100%, var(--tj-color-common-white) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.feature-item-two:hover .desc {
  color: var(--tj-color-common-white);
}

.feature-geroup-image {
  position: relative;
}
.feature-geroup-image .image-2 {
  position: absolute;
  right: -32%;
  top: -15%;
}
.feature-geroup-image .image-3 {
  position: absolute;
  left: 6%;
  top: -18%;
}

/* !END: Feature CSS */
/**----------------------------------------
START: Footer CSS
----------------------------------------*/
.tj-footer-area.footer-v3 {
  background: var(--tj-color-light-7);
}

.footer-menu-area {
  position: relative;
  z-index: 3;
  padding-bottom: 75px;
  padding-top: 100px;
  border-bottom: 1px solid var(--tj-color-light-6);
}
.footer-menu-area .container {
  position: relative;
  z-index: 999;
}

.footer-subcribe-top {
  padding-bottom: 35px;
  padding-top: 95px;
}

.tj-subcribe-box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}
.tj-subcribe-box .subcribe-title .title {
  max-width: 340px;
  margin-bottom: 0;
}
.tj-subcribe-box .subcribe-title .active-color {
  background: linear-gradient(90deg, var(--tj-color-gradient-1) 0%, var(--tj-color-gradient-3) 100%, var(--tj-color-gradient-2) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: var(--tj-ff-heading);
}
.tj-subcribe-box .subcribe-form {
  border-radius: 10px;
  background: var(--tj-color-common-white);
  box-shadow: 15px 9px 30px 0px rgba(117, 77, 233, 0.15);
  padding: 36px 45px;
  position: relative;
  width: 545px;
}
.tj-subcribe-box .subcribe-form input[type=text] {
  padding-left: 8px;
}
.tj-subcribe-box .subcribe-form input[type=email] {
  padding-left: 10px;
}
.tj-subcribe-box .subcribe-form i {
  position: absolute;
  font-size: 22px;
  left: 20px;
  top: 55%;
  transform: translateY(-50%);
}
.tj-subcribe-box .subcribe-form .tj-subcribe-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
}

.footer-widget-content .footer-logo {
  max-width: 185px;
  width: 100%;
  margin-bottom: 30px;
}
.footer-widget-content .footer-logo a {
  display: inline-block;
}
.footer-widget-content .footer-logo a img {
  width: 100%;
}
.footer-widget-content .desc {
  margin-bottom: 25px;
}
.footer-widget-content .footer-social-list ul li {
  display: inline-block;
  margin-right: 10px;
}
.footer-widget-content .footer-social-list ul li:last-child {
  margin-right: 0;
}
.footer-widget-content .footer-social-list ul li a {
  width: 35px;
  height: 35px;
  line-height: 35px;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  border-radius: 50%;
  color: var(--tj-color-common-white);
  background: var(--tj-color-theme-secondary);
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}
.footer-widget-content .footer-social-list ul li a:hover {
  background: linear-gradient(90deg, var(--tj-color-gradient-1) 0%, var(--tj-color-gradient-3) 100%, var(--tj-color-gradient-2) 100%);
  color: var(--tj-color-common-white);
}

.footer-widget {
  margin-bottom: 40px;
}
.footer-widget .title {
  color: var(--tj-color-theme-primary);
  display: block;
  margin-bottom: 20px;
}

.footer1-col-2,
.footer1-col-3,
.footer1-col-4,
.footer1-col-5 {
  padding-left: 75px;
}

.widget_nav_menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.widget_nav_menu ul li {
  margin-bottom: 15px;
}
.widget_nav_menu ul li a {
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}
.widget_nav_menu ul li a:hover {
  background: linear-gradient(90deg, var(--tj-color-gradient-1) 0%, var(--tj-color-gradient-3) 100%, var(--tj-color-gradient-2) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.tj-subcribe-content {
  padding: 45px 40px;
  border-radius: 15px;
  border: 2px solid var(--tj-color-gradient-1);
  background: var(--tj-color-common-white);
  box-shadow: 15px 9px 30px 0px rgba(117, 77, 233, 0.15);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 3;
}
.tj-subcribe-content .content-left {
  width: calc(100% - 175px);
}
.tj-subcribe-content .tj-subcribe-button {
  width: 160px;
  margin-left: 15px;
}
.tj-subcribe-content .title {
  background: linear-gradient(90deg, var(--tj-color-gradient-1) 0%, var(--tj-color-gradient-3) 100%, var(--tj-color-gradient-2) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.tj-subcribe-content .desc {
  margin-bottom: 0;
}

.subcribe-form input::placeholder {
  color: rgb(163, 169, 172);
  opacity: 1;
}

.subcribe-form input:-ms-input-placeholder {
  color: rgb(163, 169, 172);
}

.subcribe-form input::-ms-input-placeholder {
  color: rgb(163, 169, 172);
}

.copyright-area {
  padding: 25px 0;
}
.copyright-area p {
  font-family: var(--tj-ff-heading);
  font-weight: var(--tj-fw-regular);
  margin-bottom: 0;
  position: relative;
  z-index: 3;
}
.copyright-area a {
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}
.copyright-area a:hover {
  background: linear-gradient(90deg, var(--tj-color-gradient-1) 0%, var(--tj-color-gradient-3) 100%, var(--tj-color-gradient-2) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.footer-v2 {
  position: relative;
  overflow: hidden;
}
.footer-v2 .footer-menu-area {
  padding-bottom: 75px;
  padding-top: 100px;
  position: relative;
  z-index: 3;
  border-bottom: 1px solid var(--tj-color-light-6);
}
.footer-v2 .footer_shape {
  position: absolute;
  left: 0;
  bottom: 0;
}
.footer-v2 .footer_shape1 {
  position: absolute;
  bottom: 0;
  right: 0;
}
.footer-v2 .footer-star-group .star-1 {
  position: absolute;
  top: 15%;
  left: 8%;
  animation: rotateImg 15s infinite linear;
}
.footer-v2 .footer-star-group .star-2 {
  right: 12%;
  position: absolute;
  top: 18%;
  animation: rotateImg 15s infinite linear;
}
.footer-v2 .footer-star-group .star-3 {
  right: 8%;
  position: absolute;
  top: 45%;
  animation: rotateImg 15s infinite linear;
}

.footer-v3 {
  position: relative;
}
.footer-v3 .footer-shape {
  position: absolute;
  left: 10px;
  bottom: 25%;
}
.footer-v3 .footer-shape1 {
  position: absolute;
  right: 4%;
  top: 20%;
}

/* !END: Footer CSS */
/**----------------------------------------
START: Slider CSS
----------------------------------------*/
.tj-slider-section {
  padding-top: 220px;
  padding-bottom: 310px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}
.tj-slider-section .swiper-pagination {
  width: auto;
  right: 80px;
  left: 50%;
  bottom: 0;
  transform: translate(-50%);
}
.tj-slider-section .swiper-pagination .swiper-pagination-bullet {
  display: inline-block;
}
.tj-slider-section .swiper-pagination .swiper-pagination-bullet:last-child {
  margin-bottom: 0;
}
.tj-slider-section .swiper-pagination-bullet {
  background: var(--tj-color-light-2);
  width: 8px;
  height: 5px;
  cursor: pointer;
  border-radius: 10px;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}
.tj-slider-section .swiper-pagination-bullet-active {
  background: var(--tj-color-light-1);
  background: var(--tj-color-light-1);
  width: 21px;
  height: 5px;
  position: relative;
}

.slider-box {
  position: relative;
  top: 85px;
}
.slider-box .slider-shape {
  position: absolute;
  right: 70px;
  top: 10px;
}
.slider-box .active-text {
  border-radius: 55px;
  background: var(--tj-color-light-7);
  color: var(--tj-color-theme-secondary);
  padding: 10px 10px;
  margin-left: 100px;
  display: inline-block;
  margin-bottom: 30px;
  position: relative;
  z-index: 9;
}
.slider-box .active-text i {
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  text-align: center;
  border-radius: 50%;
  background: var(--tj-color-extra-3);
  color: var(--tj-color-common-white);
  display: inline-block;
}

.slider-area {
  box-shadow: -16px 16px 100px 0px rgba(190, 172, 245, 0.3);
  border-radius: 10px;
  background: var(--tj-color-common-white);
  padding: 10px 10px;
  max-width: 650px;
  max-height: 385px;
  height: 100%;
  width: 100%;
}

.slider-content-area {
  padding-left: 23.5%;
}
.slider-content-area .desc {
  max-width: 700px;
  margin-bottom: 35px;
}

.slider-item {
  margin-bottom: 60px;
}
.slider-item img {
  width: 100%;
}

/* !END: Slider CSS */
/**----------------------------------------
START: Solution CSS
----------------------------------------*/
.tj-solution-section {
  background: var(--tj-color-grey-6);
  padding-top: 150px;
  padding-bottom: 265px;
  position: relative;
}

.tj-solution-content-one {
  margin-top: 200px;
  margin-bottom: 50px;
}
.tj-solution-content-one .tj-sec-heading .title {
  max-width: 480px;
}
.tj-solution-content-one .desc {
  margin-bottom: 25px;
  max-width: 650px;
}
.tj-solution-content-one .check-list {
  max-width: 565px;
  margin-bottom: 35px;
}
.tj-solution-content-one .check-list ul li {
  margin-bottom: 15px;
  position: relative;
  font-weight: var(--tj-fw-sbold);
  font-family: var(--tj-ff-heading);
  font-size: 18px;
  padding-left: 30px;
  color: var(--tj-color-heading-primary);
}
.tj-solution-content-one .check-list ul li i {
  margin-right: 5px;
  position: absolute;
  top: 20px;
  transform: translateY(-50%);
  left: 0;
}

.solution-group-image {
  position: relative;
  right: -30px;
}
.solution-group-image .image-2 {
  position: absolute;
  bottom: -45%;
  right: -16%;
  z-index: 3;
}
.solution-group-image .image-3 {
  position: absolute;
  bottom: -75%;
  left: -30%;
}
.solution-group-image .image-4 {
  position: absolute;
  top: -10px;
  left: -40%;
}
.solution-group-image .image-5 {
  position: absolute;
  top: -30px;
  right: -20%;
}

/* !END: Solution CSS */
/**----------------------------------------
START: Breadcrumb CSS
----------------------------------------*/
.breadcrumb-wrapper {
  padding-top: 270px;
  padding-bottom: 115px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}
.breadcrumb-wrapper::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: linear-gradient(90deg, var(--tj-color-gradient-1) 0%, var(--tj-color-gradient-3) 100%, var(--tj-color-gradient-2) 100%);
}

.breadcrumb-content-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 9;
}
.breadcrumb-content-area .breadcrumb-heading {
  margin-bottom: 35px;
}
.breadcrumb-content-area .breadcrumb-heading .sub-title {
  color: var(--tj-color-common-white);
  font-weight: var(--tj-fw-bold);
  font-size: 20px;
  display: block;
  margin-bottom: 20px;
}
.breadcrumb-content-area .breadcrumb-heading .breadcrumb-title {
  color: var(--tj-color-common-white);
  display: block;
  margin-bottom: 0;
  font-size: 50px;
  line-height: 60px;
  max-width: 630px;
}
.breadcrumb-content-area .breadcrumb-link span {
  color: var(--tj-color-common-white);
  font-size: 22px;
}
.breadcrumb-content-area .breadcrumb-link i {
  color: var(--tj-color-common-white);
  padding-left: 20px;
  padding-right: 20px;
  font-size: 22px;
}
.breadcrumb-content-area .breadcrumb-link a span {
  color: var(--tj-color-common-white);
  transition: 0.4s;
  position: relative;
}
.breadcrumb-content-area .breadcrumb-link a span::before {
  position: absolute;
  content: "";
  width: 0;
  height: 1px;
  bottom: 0;
  left: 0;
  background: var(--tj-color-common-white);
}
.breadcrumb-content-area .breadcrumb-link a span:hover::before {
  width: 100%;
}

/* !END: Breadcrumb CSS */
/**----------------------------------------
START: Preloder CSS
----------------------------------------*/
#loading {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 99999;
  margin-top: 0px;
  top: 0px;
}

#loading::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--tj-color-gradient-1);
  z-index: -1;
}

#loading #loading-center {
  width: 100%;
  height: 100%;
  position: relative;
}

#loading #loading-center #loading-center-absolute {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 200px;
  width: 200px;
  margin-top: -100px;
  margin-left: -100px;
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

#loading #loading-center #loading-center-absolute .object {
  border-radius: 50% 50% 50% 50%;
  position: absolute;
  border-top: 5px solid var(--tj-color-extra-3);
  border-bottom: 5px solid transparent;
  border-left: 5px solid var(--tj-color-extra-3);
  border-right: 5px solid transparent;
  -webkit-animation: animate 2s infinite;
  animation: animate 2s infinite;
}

#loading #loading-center #loading-center-absolute .object-1 {
  border-radius: 50% 50% 50% 50%;
  position: absolute;
  border-top: 5px solid var(--tj-color-extra-1);
  border-bottom: 5px solid transparent;
  border-left: 5px solid var(--tj-color-extra-1);
  border-right: 5px solid transparent;
  -webkit-animation: animate 2s infinite;
  animation: animate 2s infinite;
}

#loading #loading-center #loading-center-absolute .object-2 {
  border-radius: 50% 50% 50% 50%;
  position: absolute;
  border-top: 5px solid var(--tj-color-extra-2);
  border-bottom: 5px solid transparent;
  border-left: 5px solid var(--tj-color-extra-2);
  border-right: 5px solid transparent;
  -webkit-animation: animate 2s infinite;
  animation: animate 2s infinite;
}

#loading #loading-center #loading-center-absolute .object-3 {
  border-radius: 50% 50% 50% 50%;
  position: absolute;
  border-top: 5px solid var(--tj-color-extra-4);
  border-bottom: 5px solid transparent;
  border-left: 5px solid var(--tj-color-extra-4);
  border-right: 5px solid transparent;
  -webkit-animation: animate 2s infinite;
  animation: animate 2s infinite;
}

#loading #loading-center #loading-center-absolute #object_one {
  left: 75px;
  top: 75px;
  width: 50px;
  height: 50px;
}

#loading #loading-center #loading-center-absolute #object_two {
  left: 65px;
  top: 65px;
  width: 70px;
  height: 70px;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

#loading #loading-center #loading-center-absolute #object_three {
  left: 55px;
  top: 55px;
  width: 90px;
  height: 90px;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

#loading #loading-center #loading-center-absolute #object_four {
  left: 45px;
  top: 45px;
  width: 110px;
  height: 110px;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

#loading #loading-center .closeLoader {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 1;
}

@-webkit-keyframes animate {
  50% {
    -webkit-transform: rotate(360deg) scale(0.8);
    transform: rotate(360deg) scale(0.8);
  }
}
@keyframes animate {
  50% {
    -webkit-transform: rotate(360deg) scale(0.8);
    transform: rotate(360deg) scale(0.8);
  }
}
/* !END: Preloder CSS *//*# sourceMappingURL=main.css.map */