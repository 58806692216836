.css-dip3t8 {
    position: relative;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 3;
    background-color: #FFFFFF !important;
}
.text-01{
padding-top: 10px;
font-weight: 500;
color: #22222299;
font-size: 23px;
}
.admin-data-grid{
    height: 800px;
    width: 1480px;
    background: white;
    margin: 20px 30px;
    border-radius: 10px;
    border: none;
    color: #1F074F;
    text-align: center;
    overflow-x: none;
}
.atl-main-div{
    width:auto;
    min-height:100%; 
    background:white;
    margin:20px;
    /* margin-left:50px; */
    margin-top:40px;
    border-radius:10px;
    padding:0px 10px 0px 20px ;
}
.atl-header{
    display:flex;
 justify-content:space-between;
}
.atl-title-design{
    width:16px;
    height:32px;
    background:#0B7974;
    border-radius:10px;
    margin-left:30px; 
    margin-top: 10px; 
}
.atl-title{
    font-size: 23px;
     font-weight: 500;
      color: black;
       padding-left: 30px;
       padding-bottom:30px ;
       padding-top: 10px;
}
.atl-filter-btn{
    width:122px;
     height:40px;
     color:rgba(111, 118, 126, 1);
     border:none;
     border-radius:10px;
     margin-right:20px;
     margin-top: 10px;
}
.admin-main-height {
    padding: 0px;
    width: calc(100% - 260px) !important;
    background: #fafafa;
  }
  
  .admin-dashboard-main-div {
    /* width: 100%; */
    background: white;
    border-radius: 10px;
    margin: 20px 20px 0px 20px;
    padding: 20px;
  }
  .input-container {
 
    display: flex;
    justify-content: space-between;
  }
  @media only screen and (max-width: 1024px) {
    .input-container {
      flex-direction: column; 
    }
  }
@media (min-width:  767px) and (max-width: 900px) {

    .admin-data-grid{
        height: 800px;
        width: 430px;
    }
    .text-01{
        padding-top: 10px;
        font-weight: 500;
        color: #22222299;
        font-size: 18px;
        }
}
@media (min-width:  901px) and (max-width: 1100px) {

    .admin-data-grid{
        height: 800px;
        width: 700px;
    }
    .text-01{
        padding-top: 10px;
        font-weight: 500;
        color: #22222299;
        font-size: 18px;
        }
}