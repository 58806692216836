.ctl-main{
    width:1610px;
     height:82vh;
     background:white;
     margin-top:40px;
     /* margin-left:50px; */
     border-radius:10px;
     padding:0px 10px 0px 20px ;
}
.ctl-header{
    display:flex;
    background:white;
     margin-top:20px;
     justify-content:space-between;
}
.ctl-header-design{
    width:16px;
    height:32px;
    background:#0B7974;
    border-radius:10px;
    margin-left:30px;
}
.ctl-title{
    font-size: 23px;
     font-weight: 500;
      color: black;
       padding-left: 30px;
       padding-bottom:30px;
}

.consumer-atl-title-design{
    width: 16px;
    height: 32px;
    background: #0B7974;
    border-radius: 10px;
    margin-top: 10px;
}

.consumer-transection-tabel-search-bar{
    display: flex;
    justify-content: space-between;
    /* margin:0px 20px 10px; */
}

.mobile-resposive{
    display: none;
}

.transections-mobile-cards{
    height: 144px;
    background-color: #FFFFFF;
    padding: 16px;
    border-radius: 12px;
    margin-bottom: 15px;
}

@media (max-width: 1200px) {
    .consumer-transection-tabel-search-bar{
        flex-direction: column;
        margin: 0px !important;
    }
  }

  @media (max-width: 425px) {
    /* .transections-table{
        display: none;
    } */
    .mobile-resposive{
        display: block;
        margin-top: 15px;
    }

    .consumer-transection-tabel-search-bar{
        margin: 0px !important;
    }
  }