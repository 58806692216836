.signup-popup {
  width: 950px;
  height: 800px;
  border-radius: 10px;
  background-color: white;
  display: flex;
}
.clear {
  background: none;
  border: none;
  width: 34px;
  height: 34px;
  margin-left: 560px;
}
.in-signup-popup {
  width: 950px;
  height: 850px;
  border-radius: 10px;
  background-color: white;
  display: flex;
}
.signup-main-container {
  width: 100%;
  height:100vh;
  border: none;
  box-shadow: 5px 5px 25px #2222220f;
}

.login-left-side-main-container{
  width: 100%;
  height: 100%;
}

.login-left-side-1 {
  width: 100%;
  height: 100%;
  border: none;
  background: rgba(11, 121, 116, 1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.login-left-side-container1{
  width: 559px;
  margin: auto;
}
.login-left-text-11 {
  font-size: 25px;
  font-weight: 700;
  color: white;
  text-align: justify;
}
.login-lect-text-11 {
  font-size: 18px;
  color: white;
  text-align: center;
  padding-top: 20px;
}
.signup-img-1 {
  width: 625.27px;
  height: 471.71000000000004px;
  margin: 0 auto;
}

.signup-right-side-1 {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  border: none;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.signup-right-side-1-form{
  width: 501px !important;
  height: 90%;
}
.signup-logo-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.add-profile-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.css-1bn53lx.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: rgb(189, 189, 189) !important;
}

@media (max-width: 1200px) {
  .login-left-side-container1{
    width: 359px;
    padding:50px 60px;
  }
  .login-left-text-11{
    font-size: 26px;
  }
  .signup-right-side-1-form{
    width: 401px !important;
  }
}

@media (max-width: 900px) {
  .login-left-side-main-container{
    display: none;
  }
}

@media (max-width: 425px) {
  .signup-right-side-1-form{
    width: 300px !important;
  }
}
