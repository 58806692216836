.segment-name{
    font-size: 18px !important;
    color: black !important;
    font-weight: 500 !important;
}
.segment-name-span{
    font-size: 12px !important;
    color:#0B7974 !important
}
.segment-name-value{
    font-size: 18px !important;
    color: #6F767E !important;
}
.segment-name-value-span{
    font-size: 12px !important;
    color:#FF5833 !important
}