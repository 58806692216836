.text11 {
  width: 250px;
  margin-left: 830px;
}

.text2 {
  width: 250px;
  margin-left: 120px;
}

.signup-popup {
  border-radius: 10px;
  background-color: white;
  display: flex;
}

.in-signup-popup {
  border-radius: 10px;
  background-color: white;
  display: flex;
}

.login-main-container-1 {
  width: 100%;
  height: 100vh;
  border-radius: 10px;
  border: none;
  box-shadow: 5px 5px 25px #2222220f;
}

.login-left-side-1 {
  width: 100%;
  height: 100%;
  border: none;
  background: rgba(11, 121, 116, 1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.login-left-side-container1{
  width: 559px;
  margin: auto;
}

.login-left-text-11 {
  font-size: 25px;
  font-weight: 700;
  color: white;
  text-align: justify;
}

.login-lect-text-11 {
  font-size: 18px;
  color: white;
  text-align: center;
  padding-top: 20px;
}

.login-img-1 {
  width: 625.27px;
  height: 471.71000000000004px;
  margin: 0 auto;
}

.login-logo-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-logo-1 {
  width: 186px;
  height: 69px;
}

.login-text-1 {
  font-weight: 700 !important;
  color: #0b7974;
  font-size: 32px !important;
  padding-top: 100px;
}

.divider{
  padding-top: 20px;
}

.select-rol-container{
  display: flex;
  justify-content: center;
}

.login-right-side-1 {
  width: 100%;
  height: 100%;
  border: none;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-right-side-1-form{
  width: 501px;
}

.line-1 {
  border: 0.5px solid #e9ebed;
  width: 180px;
  padding-right: 10px;
  margin-left: 180px;
}

.line-2 {
  border: 0.5px solid #e9ebed;
  width: 180px;
  margin-right: 180px;
}

@media (max-width: 1200px) {
  .login-left-side-container1{
    width: 359px;
    padding:50px 60px;
  }
  .login-left-text-11{
    font-size: 26px;
  }
  .login-right-side-1-form{
    width: 401px;
  }
}

@media (max-width: 900px) {
  .login-left-side-main-container{
    display: none;
  }
}

@media (max-width: 425px) {
  .login-right-side-1-form{
    width: 300px;
  }
}
