.text1 {
    width: 250px;
    margin-left: 830px;
}

.text2 {
    width: 250px;
    margin-left: 120px;
}

.signup-popup {
    /* width: 950px;
    height: 800px; */
    border-radius: 10px;
    background-color: white;
    display: flex;
}

.in-signup-popup {
    /* width: 950px;
    height: 850px; */
    border-radius: 10px;
    background-color: white;
    display: flex;
}

/* login screens */

.login-main-container {
    width: 100%;
    height: 100vh;
    border-radius: 10px;
    border: none;
    box-shadow: 5px 5px 25px #2222220F;
}

.login-left-side {
    /* width: 100%; */
    height: 100vh;
    border: none;
    background: rgba(11, 121, 116, 1);
}

.login-left-text-2 {
    font-size: 36px;
    font-weight: 700;
    color: white;
    text-align: center;
    padding-left: 28%;
    padding-right: 28%;
    padding-top: 93px;
}

.login-lect-text-1 {
    font-size: 18px;
    /* font-weight: 400; */
    color: white;
    text-align: center;
    padding-left: 25%;
    padding-right: 25%;
    padding-top: 20px;
}

.login-img {
    margin-top: 160px;
    Width: 625.27px;
    Height: 471.71000000000004px;
    margin-left: 190px;
}
.login-logo {
    width: 186px;
    height: 69px;
    margin-left: 330px;
    margin-top: 100px;
}

.login-text {
    display: flex;
    justify-content: center;
    padding-top: 70px;
    padding-left: 60px;
    font-weight: 700;
    color: #0B7974;
    font-size: 32px;
}

.login-right-side {
    width: 100%;
    height: 100vh;
    border: none;
    background:  rgba(239, 239, 239, 1);
}

.line-1 {
    border: 0.5px solid #E9EBED;
    width: 180px;
    padding-right: 10px;
    margin-left: 180px;
}

.line-2 {
    border: 0.5px solid #E9EBED;
    width: 180px;
    margin-right: 180px;
}
.add-login-btn {
    width: 120px;
    height: 40px;
    border-radius: 10px;
    border: none;
    background:#fcfbfb;
    color: #050505;
    /* margin-right:20px; */
    margin-top: 20px;
    cursor: pointer;
    margin-left:10px;
    font-weight: 700;
  }
  
  .filter-icon{
    width: 20px;
    height: 20px;
    margin-right: 10px;
    margin-top: 10px;
  }
.admin-login-inputs{
    margin-right: 100px;
    margin-left: 200px;
}
@media only screen and (max-width: 1024px) {
    .login-left-text-2{
       
        letter-spacing: -2px;
    }
    .login-img{
        Height:70vh
    }
    .login-right-side{
        height:126vh;
    }
}
@media only screen and (max-width: 1615px) {
    .login-main-container {
        flex-wrap: nowrap !important;
    }
    .login-img{
        margin-left: 0;
    }
    .login-left-side{
        height: auto;
        /* width:50rem */
    }
    .login-left-text-2{
        padding-left: 60px;
        padding-right: 60px;
        text-align: justify;
        /* letter-spacing: -2px; */
    }
    .login-lect-text-1{
         padding-left: 7%;
         padding-right: 7%
         
    }
    .admin-login-inputs{
        margin-right: 100px;
        margin-left: 60px;
    }
    .login-text{
        padding-left: 0px;
    }
    .login-logo{
margin-left: 235px;
    }
}
@media only screen and (max-width: 1615px) {
    .login-main-container {
        flex-wrap: nowrap !important;
    }}

/* @media (min-width: 1440px) and (max-width: 2560px) {
    .login-main-container {
        width: 100%;
        height: 100vh;
        border-radius: 10px;
        border: none;
        box-shadow: 5px 5px 25px #2222220F;
    }

     
    

    .login-lect-text-1 {
        font-size: 16px;
        font-weight: 400;
        color: white;
        text-align: center;
        padding-left: 7%;
        padding-right: 9%;
        padding-top: 20px
    }

    .login-img {
        margin-top: 209px;
        Width: 449.27px;
        Height: 358.71000000000004px;
        margin-left: 53px;
    }

    .login-right-side {
        width: 100%;
        height: 100vh;
        border: none;
        background: #FFFFFF;
    }

  
} */


 

  /* @media only screen and (min-width: 600px) {
    .login-main-container {
        width: 100%;
        height: 100vh;
        border-radius: 10px;
        border: none;
        box-shadow: 5px 5px 25px #2222220F;
    }

     
    

    .login-lect-text-1 {
        font-size: 16px;
        font-weight: 400;
        color: white;
        text-align: center;
        padding-left: 7%;
        padding-right: 9%;
        padding-top: 20px
    }

    .login-img {
        margin-top: 209px;
        Width: 449.27px;
        Height: 358.71000000000004px;
        /* margin-left: 53px; */
    /* }

    .login-right-side {
        width: 100%;
        height: 100vh;
        border: none;
        background: #FFFFFF;
    }
  }  */