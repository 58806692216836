html,
body,
#root {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  /* overflow: hidden; */
}
& .css-byenzh-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
  color: none;
}
