.cl-main-div {
  width: auto;
  height: 800px;
  background: white;
  margin-top: 40px;
  border-radius: 10px;
  overflow: auto;
}

.cl-header-div {
  display: flex;
  justify-content: space-between;
}

.cl-title-design {
  width: 16px;
  height: 32px;
  background: #0B7974;
  border-radius: 10px;
  margin-left: 30px;
  margin-top: 10px;
}

.cl-title {
  font-size: 23px;
  font-weight: 500;
  color: black;
  padding-left: 30px;
  padding-top: 10px;
}

.cl-active-btn {
  width: 99px;
  height: 40px;
  border-radius: 10px;
  margin-right: 20px;
  border: none;
}

.cl-add-btn {
  width: 159px;
  height: 40px;
  background: #FF5833;
  border-radius: 10px;
  border: none;
  margin-top: 20px;
  margin-right: 20px;
  color: white;
}

.cl-card {
  display: flex;
  margin-top: 20px;
  margin-left: 30px;
  width: 318px;
  height: 124px;
  border-radius: 10px;
  border: 1px solid rgba(111, 118, 126, 0.5);
}

.cl-card-img {
  object-fit: fill;
  width: 80px;
  height: 80px;
  margin-top: 20px;
  margin-left: 20px;
}

.cl-brand-name {
  font-size: 16px;
  font-weight: 500;
  color: #1F074F;
  padding-left: 20px;
}

.cl-email {
  font-size: 12px;
  font-weight: 500;
  color: #22222299;
  padding-left: 20px;
}

.cl-number {
  font-size: 12px;
  font-weight: 500;
  color: #22222299;
  padding-left: 20px;
}

.cl-btns {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: 20px;
}

.cl-edit-btn {
  width: 42px;
  height: 40px;
  border-radius: 10px;
  border: none;
  background: rgba(11, 121, 116, 1);
  margin-top: 20px;
}

.cl-switch-btn {
  width: 46px;
  height: 40px;
  border-radius: 10px;
  border: none;
  background: rgba(11, 121, 116, 1);
  margin-top: 20px
}

.dec-popup {
  width: 500px;
  height: 160px;
  background-color: white;
  border-radius: 10px;

}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.cross-btn {
  background: none;
  border: none;
  margin-left: 860px;
}

.dec-card-header {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
}

.dec-card-title-design {
  width: 16px;
  height: 32px;
  background: #0B7974;
  border-radius: 5px;
  margin-left: 30px;
}

.dec-card-title {
  font-size: 23px;
  font-weight: 500;
  color: black;
  padding-left: 30px;
  padding-bottom: 30px;
}

.dec-cancel-btn {
  width: 156px;
  height: 42px;
  border: 1px solid rgba(11, 121, 116, 1);
  border-radius: 10px;
  font-size: 18px;
  background: none;
  margin-top: 20px;
  margin-left: 150px;
  color: rgba(11, 121, 116, 1);
}

.dec-invite-btn {
  width: 156px;
  height: 42px;
  border-radius: 10px;
  font-size: 18px;
  background: rgba(11, 121, 116, 1);
  color: white;
  margin-top: 20px;
  margin-left: 20px;
  border: none;
}
.dec-btn{
  width:36px;
   height:36px;
   background:rgba(111, 118, 126, 1);
   color:white;
   border:none;
   border-radius:50%;
   margin-right:20px;
}

.cl-card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-left: -20px; /* Adjust for card margins */
}

.loader-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.admin-store-main-div {
  width: auto;
  background: white;
  border-radius: 10px;
  padding: 0px 10px 0px 20px;
}
.admin-main-height {
  padding: 0px;
  width: calc(100% - 260px) !important;
  background: #fafafa;
}
.admin-dashboard-main-div {
  /* width: 100%; */
  background: white;
  border-radius: 10px;
  margin: 20px 20px 0px 20px;
  padding: 20px;
}
.input-container{
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-left: 20px;
}

@media only screen and (max-width: 1024px) {
  .cl-card {
      width: 295px !important;
  }
  .input-container {
    flex-direction: column;
  }
  .cl-card-container {
    gap: 10px;
  }
}

