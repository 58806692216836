.uc-main-div{
    /* width:1315px; */
    width:100%;
    height:auto;
     background:white;
     border-radius:10px;
      margin-top:20px;
      /* margin-left:160px; */
}
.uc-title-design{
    width:16px;
    height:32px;
    background:#0B7974;
    border-radius:10px;
    margin-left:30px;
}
.uc-title{
    font-size: 23px;
     font-weight: 500;
      color: black;
       padding-left: 30px;
}
.uc-avatar{
    width:96px;
    height:96px;
    border-radius:48px ;
    margin-left:30px;
     margin-top:20px;
}
.uc-update-pic-btn-1{
    width:200px;
    height:48px;
     border-radius:10px;
     background:#0B7974;
      color:white;
      border:none;
      margin-top:45px;
      margin-left:40px;
}
.uc-remove-btn{
    width:103px;
    height:48px;
     border-radius:10px;
     background:#EFEFEF;
      color:black;
      border:none;
       margin-top:45px;
       margin-left:20px;
}
.uc-field{
    /* margin-left:50px;
    margin-top:20px; */
}
.uc-back-btn{
    width:240px;
    height:52px;
     border:1px solid #0B7974;
     color:#0B7974;
     background:white;
     border-radius:10px;
     /* margin-top:40px; */
      /* margin-left:20px; */
}
.uc-update-btn{
    width:240px;
    height:52px;
     border:none;
     color:white;
     background:#0B7974;
     border-radius:10px;
     /* margin-top:40px; */
      /* margin-left:20px; */
}

.consumer-update-profile-user{
    padding: 30px 40px;
}

.consumer-update-profile-form{
    padding: 20px 40px !important;
}

@media (max-width: 425px) {
    .consumer-update-profile-user{
        padding: 30px 0px 30px 10px;
    }
    .consumer-update-profile-form{
        padding: 20px 10px !important;
    }
  }